/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import CitiesEditor from '../Editor/CitiesEditor.js';
import CitiesGridColumns from './Columns/CitiesGridColumns.js';

export default function CitiesSideGridEditor(props) {
	return <SideGridEditor
				reference="CitiesSideGridEditor"
				model="Cities"
				usePermissions={true}
				isCollapsible={false}
				Editor={CitiesEditor}
				columnsConfig={CitiesGridColumns}
				
				
				{...props}
			/>;
}