/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import CitiesGridPanel from './CitiesGrid.js';

export default function CitiesFilteredGridPanel(props) {
	return <CitiesGridPanel
				reference="CitiesFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}