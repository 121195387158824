/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import PickupsEditorWindow from '../../Window/PickupsEditorWindow.js';
import PickupsGridColumns from '../../Grid/Columns/PickupsGridColumns.js';

export default function PickupsGridEditorPanel(props) {
	return <GridPanel
				reference="PickupsGridEditorPanel"
				model="Pickups"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={PickupsEditorWindow}
				columnsConfig={PickupsGridColumns}
				canRowsReorder={true}
				
				{...props}
			/>;
}