/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import PickupsGridColumns from './Columns/PickupsGridColumns.js';

export default function PickupsGrid(props) {
	return <Grid
				reference="PickupsGrid"
				model="Pickups"
				usePermissions={true}
				columnsConfig={PickupsGridColumns}
				canRowsReorder={true}
				{...props}
			/>;
}