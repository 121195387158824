export default [
	    {
	        "id": "routes__name",
	        "header": "Name",
	        "fieldName": "routes__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 250 // MOD
	    }
	];