/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import CollectionsSideGridEditorPanel from './CollectionsSideGridEditor.js';


export default function CollectionsFilteredSideGridEditorPanel(props) {
	return <CollectionsSideGridEditorPanel
				reference="CollectionsFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}