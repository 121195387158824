import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import DriversFilteredGridEditor from '../components/Grid/DriversFilteredGridEditor.js';
import DriversFilteredSideGridEditor from '../components/Grid/DriversFilteredSideGridEditor.js';

export default function DriversManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'DriversManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Drivers"
				reference="DriversManager"
				fullModeComponent={<DriversFilteredGridEditor
										reference="DriversGridEditor"
									/>}
				sideModeComponent={<DriversFilteredSideGridEditor
										reference="DriversSideGridEditor"
									/>}
			/>;
}
