import ArrayCombo from '@onehat/ui/src/Components/Form/Field/Combo/ArrayCombo.js';

const data = [
	[ 10, '10%', ],
	[ 20, '20%', ],
	[ 30, '30%', ],
	[ 40, '40%', ],
	[ 50, '50%', ],
	[ 60, '60%', ],
	[ 70, '70%', ],
	[ 80, '80%', ],
	[ 90, '90%', ],
	[ 100, '100%', ],
];

export default function PercentagesCombo(props) {
	return <ArrayCombo
				data={data}
				disableDirectEntry={true}
				{...props}
			/>;
}
