import Report from '@onehat/ui/src/Components/Report/Report.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import Globals from '../../AppGlobals.js';

function ReportComponent(props) {
	const {
			self,
		} = props;
	return <Report
				title="Active Customer List by City"
				description="Lists all customers in system, grouped by city"
				reportId={2}
				disablePdf={true}
				_form={{
					items: [
						{
							"type": "Column",
							"flex": 1,
							"defaults": {},
							"items": [
								{
									name: 'route_id',
									type: 'RoutesCombo',
									label: 'Route',
									allowBlank: true,
									tooltip: 'Which route to display results for'
								},
							]
						},
						{
							"type": "Column",
							"flex": 1,
							"defaults": {},
							"items": [
								{
									name: 'city_id',
									type: 'CitiesCombo',
									label: 'City',
									allowBlank: true,
									tooltip: 'Which city to display results for',
								},
							]
						},
					],
				}}
				parent={self}
			/>;
}



function withAdditionalProps(WrappedComponent) {
	return (props) => {
		return <WrappedComponent
					reference="report2"
					{...props}
				/>;
	};
}
export default withAdditionalProps(withComponent(ReportComponent));
