/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import RoutesEditor from '../../Editor/RoutesEditor.js';
import RoutesGridColumns from '../../Grid/Columns/RoutesGridColumns.js';

export default function RoutesSideGridEditorPanel(props) {
	return <GridPanel
				reference="RoutesSideGridEditorPanel"
				model="Routes"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={RoutesEditor}
				columnsConfig={RoutesGridColumns}
				
				
				{...props}
			/>;
}