/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PickupsEditorWindow from '../Window/PickupsEditorWindow.js';

export default function PickupsGridEditor(props) {
	return <WindowedGridEditor
				reference="pickupsGridEditor"
				model="Pickups"
				Editor={PickupsEditorWindow}
				disableAdd={true}
				disableDelete={true}
				disableDuplicate={true}
				canRowsReorder={false}
				columnsConfig={[
				    {
				        header: 'Date', // MOD
				        "fieldName": "collections__date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200 // MOD
				    },
				    // {
				    //     "header": "Sort",
				    //     "fieldName": "pickups__sort_order",
				    //     "sortable": true,
				    //     "isEditable": true,
				    //     "reorderable": true,
				    //     "resizable": true,
				    //     "w": 100
				    // },
				    // {
				    //     "header": "Customer",
				    //     "fieldName": "customers__title",
				    //     "sortable": true,
				    //     "isEditable": true,
				    //     "reorderable": true,
				    //     "resizable": true,
				    //     "w": 200 // MOD
				    // },
				    {
				        "header": "Oil (gal)", // MOD
				        "fieldName": "pickups__gallons_oil",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150 // MOD
				    },
				    {
				        "header": "Sludge (gal)", // MOD
				        "fieldName": "pickups__gallons_sludge",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150 // MOD
				    },
				    {
				        "header": "Comments",
				        "fieldName": "pickups__comments",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200
				    },
				    {
				        "header": "Grease Trap Only?",
				        "fieldName": "pickups__is_grease_trap_only",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150 // MOD
				    }
				]}
				{...props}
			/>;
}