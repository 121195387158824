import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Drivers = {
	
	name: 'Drivers',
	
	model: {

		idProperty: 'drivers__id',
		displayProperty: 'drivers__name',
		sortProperty: 'drivers__name',
		
		sorters: null,

		validator: yup.object({
			drivers__name: yup.string().required()
		}),
		
		properties: [
			{ name: 'drivers__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'drivers__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'Collections'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getCollections: function() {
				const Collections = this.getAssociatedRepository('Collections');
				return Collections.getBy((entity) => {
					return entity.collections__driver_id === this.drivers__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Drivers;
