/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Cities from './Cities.js';
import Collections from './Collections.js';
import CollectionsRoutes from './CollectionsRoutes.js';
import Customers from './Customers.js';
import CustomersRoutes from './CustomersRoutes.js';
import Drivers from './Drivers.js';
import Groups from './Groups.js';
import GroupsUsers from './GroupsUsers.js';
import Local from './Local.js';
import Permissions from './Permissions.js';
import Prefs from './Prefs.js';
import Pickups from './Pickups.js';
import Routes from './Routes.js';
import Secure from './Secure.js';
import States from './States.js';
import Users from './Users.js';

const obj = {
	Cities,
	Collections,
	CollectionsRoutes,
	Customers,
	CustomersRoutes,
	Drivers,
	Groups,
	GroupsUsers,
	Local,
	Permissions,
	Prefs,
	Pickups,
	Routes,
	Secure,
	States,
	Users
};
export default obj;