/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import CustomersRoutesEditorWindowSideB from '../Window/CustomersRoutesEditorWindowSideB.js';
import CustomersRoutesGridColumnsSideB from './Columns/CustomersRoutesGridColumnsSideB.js';

export default function CustomersRoutesGridEditorSideB(props) {
	return <WindowedGridEditor
				reference="CustomersRoutesGridEditorSideB"
				model="CustomersRoutes"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={CustomersRoutesEditorWindowSideB}
				columnsConfig={CustomersRoutesGridColumnsSideB}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				
				{...props}
			/>;
}