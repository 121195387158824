import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import PickupsFilteredGridEditor from '../components/Grid/PickupsFilteredGridEditor.js';
import PickupsFilteredSideGridEditor from '../components/Grid/PickupsFilteredSideGridEditor.js';

export default function PickupsManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'PickupsManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Pickups"
				reference="PickupsManager"
				fullModeComponent={<PickupsFilteredGridEditor
										reference="PickupsGridEditor"
									/>}
				sideModeComponent={<PickupsFilteredSideGridEditor
										reference="PickupsSideGridEditor"
									/>}
			/>;
}
