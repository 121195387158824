/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import CustomersEditorWindow from '../Window/CustomersEditorWindow.js';
import CustomersGridColumns from './Columns/CustomersGridColumns.js';

export default function CustomersGridEditor(props) {
	return <WindowedGridEditor
				reference="CustomersGridEditor"
				model="Customers"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={CustomersEditorWindow}
				columnsConfig={CustomersGridColumns}
				
				
				{...props}
			/>;
}