/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PickupsEditor from '../Editor/PickupsEditor.js';
import PickupsGridColumns from './Columns/PickupsGridColumns.js';

export default function PickupsSideGridEditor(props) {
	return <SideGridEditor
				reference="PickupsSideGridEditor"
				model="Pickups"
				usePermissions={true}
				isCollapsible={false}
				Editor={PickupsEditor}
				columnsConfig={PickupsGridColumns}
				canRowsReorder={true}
				
				{...props}
			/>;
}