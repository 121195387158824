/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import RoutesGridEditorPanel from './RoutesGridEditor.js';

export default function RoutesFilteredGridEditorPanel(props) {
	return <RoutesGridEditorPanel
				reference="RoutesFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}