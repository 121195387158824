export default [
	    {
	        "id": "collections__date",
	        "header": "Date",
	        "fieldName": "collections__date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "collections__routes",
	        "header": "Routes",
	        "fieldName": "collections__routes",
	        "isSortable": false,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 400 // MOD
	    },
	    {
	        "id": "collections__driver_id",
	        "header": "Driver",
	        "fieldName": "drivers__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200, // MOD
	        "editField": "collections__driver_id"
	    }
	];