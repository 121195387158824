/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Combo from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';

function CustomersCombo(props) {
	return <Combo
				reference="CustomersCombo"
				model="Customers"
				uniqueRepository={true}
				usePermissions={true}
				{...props}
			/>;
}

export default CustomersCombo;