/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import CollectionsSideGridEditor from './CollectionsSideGridEditor.js';

export default function CollectionsFilteredSideGridEditor(props) {
	return <CollectionsSideGridEditor
				reference="CollectionsFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}