/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import CustomersRoutesGridColumns from '../../Grid/Columns/CustomersRoutesGridColumns.js';

export default function CustomersRoutesGridPanel(props) {
	return <GridPanel
				reference="CustomersRoutesGridPanel"
				model="CustomersRoutes"
				usePermissions={true}
				columnsConfig={CustomersRoutesGridColumns}

				{...props}
			/>;
}