/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import CustomersGridColumns from '../../Grid/Columns/CustomersGridColumns.js';
import {
	EDITOR_TYPE__INLINE,
} from '@onehat/ui/src/Constants/Editor.js';

export default function CustomersInlineGridEditorPanel(props) {
	return <GridPanel
				reference="CustomersInlineGridEditorPanel"
				model="Customers"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__INLINE}
				columnsConfig={CustomersGridColumns}
				
				
				{...props}
			/>;
}