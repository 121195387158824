/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import { useState, useEffect, } from 'react';
import { Linking } from 'react-native';
import {
	Row,
} from 'native-base';
import Url from 'url-parse';
import {
	useLinkTo,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import {
	VERTICAL,
} from '@onehat/ui/src/Constants/Directions.js';
import {
	VIEW,
} from '@onehat/ui/src/Constants/Commands.js';
import {
	setCurrentScreen,
	selectUser,
} from '../models/Slices/AppSlice';
import {
	setAlertMessage,
	setDebugMessage,
	selectDebugMessage,
	selectAlertMessage,
	selectInfoMessage,
	selectIsWaitModalShown,
	selectWaitMessage,
} from '../models/Slices/DebugSlice';
import withPermissions from '@onehat/ui/src/Components/Hoc/withPermissions';
import Loading from '@onehat/ui/src/Components/Messages/Loading';
import WaitMessage from '@onehat/ui/src/Components/Messages/WaitMessage';
import ErrorMessage from '@onehat/ui/src/Components/Messages/ErrorMessage';

import ChartPie from '@onehat/ui/src/Components/Icons/ChartPie';
import House from '@onehat/ui/src/Components/Icons/House';
import Hammer from '@onehat/ui/src/Components/Icons/Hammer';
import User from '@onehat/ui/src/Components/Icons/User';
import UserGroup from '@onehat/ui/src/Components/Icons/UserGroup';
import RightFromBracket from '@onehat/ui/src/Components/Icons/RightFromBracket';
import BoxesPacking from '../components/Icons/BoxesPacking.js';
import BoxesStacked from '../components/Icons/BoxesStacked.js';
import Route from '../components/Icons/Route.js';
import Store from '../components/Icons/Store.js';
import TruckPickup from '../components/Icons/TruckPickup.js';

import TabBar from '@onehat/ui/src/Components/Tab/TabBar.js';
import Home from '../screens/Home';
import Logout from '../screens/Logout';
import CollectionsManager from '../screens/CollectionsManager';
import CustomersManager from '../screens/CustomersManager';
import DriversManager from '../screens/DriversManager';
import PickupsManager from '../screens/PickupsManager';
import RoutesManager from '../screens/RoutesManager';
import ReportsManager from '../screens/ReportsManager';
import UsersManager from '../screens/UsersManager';
import GroupsManager from '../screens/GroupsManager';
import Util from '../screens/Util';
import AppGlobals from '../AppGlobals';
import Inflector from 'inflector-js';
import _ from 'lodash';

const
	Stack = createStackNavigator(),
	urlPrefix = '/' + AppGlobals.urlPrefix;

function AppNavigator(props) {
	const {
			// withPermissions
			canUser,
		} = props,
		linkTo = useLinkTo(),
		dispatch = useDispatch(),
		isWaitModalShown = useSelector(selectIsWaitModalShown),
		debugMessage = useSelector(selectDebugMessage),
		alertMessage = useSelector(selectAlertMessage),
		infoMessage = useSelector(selectInfoMessage),
		waitMessage = useSelector(selectWaitMessage),
		user = useSelector(selectUser),
		[currentTabIx, setCurrentTabIx] = useState(0),
		[isReady, setIsReady] = useState(false),
		[initialRouteName, setInitialRouteName] = useState('Home'),
		onChangeCurrentTab = (ix) => {
			// update state
			setCurrentTabIx(ix);

			// switch nav
			const
				currentTab = tabs[ix],
				path = currentTab.path;
			linkTo(path);
			dispatch(setCurrentScreen(currentTab.screenName));
		};

	const tabs = [
		{
			title: 'Home',
			_icon: { as: House },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Home')),
			screenName: 'Home',
		},
	];
	if (canUser(VIEW, 'Collections')) {
		tabs.push({
			title: 'Collections',
			_icon: { as: BoxesPacking },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Collections')),
			screenName: 'CollectionsManager',
		});
	}
	// if (canUser(VIEW, 'Pickups')) {
	// 	tabs.push({
	// 		title: 'Pickups',
	// 		_icon: { as: TruckPickup },
	// 		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Pickups')),
	// 		screenName: 'PickupsManager',
	// 	});
	// }
	if (canUser(VIEW, 'Routes')) {
		tabs.push({
			title: 'Routes',
			_icon: { as: Route },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Routes')),
			screenName: 'RoutesManager',
		});
	}
	if (canUser(VIEW, 'Customers')) {
		tabs.push({
			title: 'Customers',
			_icon: { as: Store },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Customers')),
			screenName: 'CustomersManager',
		});
	}
	if (canUser('view_reports')) {
		tabs.push({
			title: 'Reports',
			_icon: { as: ChartPie },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Reports')),
			screenName: 'ReportsManager',
		});
	}
	if (canUser(VIEW, 'Users')) {
		tabs.push({
			title: 'Users',
			_icon: { as: User },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Users')),
			screenName: 'UsersManager',
		});
	}
	if (canUser(VIEW, 'Groups')) {
		tabs.push({
			title: 'Groups',
			_icon: { as: UserGroup },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Groups')),
			screenName: 'GroupsManager',
		});
	}
	tabs.push({
		title: 'Utilities',
		_icon: { as: Hammer },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Util')),
		screenName: 'Util',
	});
	tabs.push({
		title: 'Logout',
		_icon: { as: RightFromBracket },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Logout')),
		screenName: 'Logout',
	});
	
	useEffect(() => {
		(async () => {
			const
				initialUrl = await Linking.getInitialURL(),
				url = new Url(initialUrl),
				path = url.pathname;

			if (!user) {
				linkTo(urlPrefix + Inflector.dasherize(Inflector.underscore('Login')));
				return;
			}

			const 
				whichTab = _.find(tabs, (tab) => {
					return tab.path === path;
				}),
				whichTabIx = whichTab ? tabs.indexOf(whichTab) : 0;

			setCurrentTabIx(whichTabIx);
			if (whichTab) {
				setInitialRouteName(whichTab.screenName);
			}
			setIsReady(true);
		})();
	}, []);

	if (!isReady) {
		return <Loading isScreen={true} />;
	}


	const navigatorOptions = {
		initialRouteName,
		screenOptions: ({ route }) => {
			return {
				headerShown: false,
				title: 'LenzOil Admin App: ' + route.name.replace('Manager', ''),
			}
		},
	};

	return <Row flex={1} w="100%">
				{isWaitModalShown && <WaitMessage text={waitMessage} />}
				{!isWaitModalShown && alertMessage && <ErrorMessage text={alertMessage} onOk={() => dispatch(setAlertMessage(null))} />}
				{!isWaitModalShown && debugMessage && <ErrorMessage text={debugMessage} color="green" onOk={() => dispatch(setDebugMessage(null))} />}
				{!isWaitModalShown && infoMessage && <ErrorMessage text={infoMessage} color="#000" onOk={() => dispatch(setInfoMessage(null))} />}

				{!!currentTabIx && currentTabIx !== tabs.length -1 && // hide TabBar if showing Home or Logout
					<TabBar
						tabs={tabs}
						direction={VERTICAL}
						tabWidth={180}
						currentTabIx={currentTabIx}
						onChangeCurrentTab={onChangeCurrentTab}
					/>}
				<Stack.Navigator {...navigatorOptions}>
					<Stack.Screen name="Home">
						{() => <Home tabs={tabs} onChangeCurrentTab={onChangeCurrentTab} />}
					</Stack.Screen>
					<Stack.Screen name="CollectionsManager" component={CollectionsManager} />
					<Stack.Screen name="CustomersManager" component={CustomersManager} />
					<Stack.Screen name="DriversManager" component={DriversManager} />
					<Stack.Screen name="PickupsManager" component={PickupsManager} />
					<Stack.Screen name="RoutesManager" component={RoutesManager} />
					<Stack.Screen name="ReportsManager" component={ReportsManager} />
					<Stack.Screen name="UsersManager" component={UsersManager} />
					<Stack.Screen name="GroupsManager" component={GroupsManager} />
					<Stack.Screen name="Util" component={Util} />
					<Stack.Screen name="Logout" component={Logout} />
				</Stack.Navigator>
			</Row>;
}

export default withPermissions(AppNavigator, true);

