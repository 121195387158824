/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import DriversGridPanel from './DriversGrid.js';

export default function DriversFilteredGridPanel(props) {
	return <DriversGridPanel
				reference="DriversFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}