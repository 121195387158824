/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import CustomersEditor from '../../Editor/CustomersEditor.js';
import CustomersGridColumns from '../../Grid/Columns/CustomersGridColumns.js';

export default function CustomersSideGridEditorPanel(props) {
	return <GridPanel
				reference="CustomersSideGridEditorPanel"
				model="Customers"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={CustomersEditor}
				columnsConfig={CustomersGridColumns}
				
				
				{...props}
			/>;
}