import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import CollectionsFilteredGridEditor from '../components/Grid/CollectionsFilteredGridEditor.js';
import CollectionsFilteredSideGridEditor from '../components/Grid/CollectionsFilteredSideGridEditor.js';

export default function CollectionsManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'CollectionsManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Collections"
				reference="CollectionsManager"
				fullModeComponent={<CollectionsFilteredGridEditor
										reference="CollectionsGridEditor"
									/>}
				sideModeComponent={<CollectionsFilteredSideGridEditor
										reference="CollectionsSideGridEditor"
									/>}
			/>;
}
