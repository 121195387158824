/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import CustomersRoutesGridPanel from './CustomersRoutesGrid.js';

export default function CustomersRoutesFilteredGridPanel(props) {
	return <CustomersRoutesGridPanel
				reference="CustomersRoutesFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}