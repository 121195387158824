import Report from '@onehat/ui/src/Components/Report/Report.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import Globals from '../../AppGlobals.js';

function ReportComponent(props) {
	const {
			self,
		} = props;
	return <Report
				title="Collection Average"
				// description="Lists all customers in system"
				reportId={3}
				disablePdf={true}
				_form={{
					items: [
						{
							"type": "Column",
							"flex": 1,
							"defaults": {},
							"items": [
								{
									name: 'startDate',
									type: 'Date',
									label: 'Start Date',
									allowBlank: true,
									tooltip: 'Earliest date to display',
								},
								{
									name: 'endDate',
									type: 'Date',
									label: 'End Date',
									allowBlank: true,
									tooltip: 'Latest date to display',
								},
								{
									name: 'customer_id',
									type: 'CustomersCombo',
									label: 'Customer',
									allowBlank: true,
									tooltip: 'Which customer to display results for',
								},
							]
						},
						{
							"type": "Column",
							"flex": 1,
							"defaults": {},
							"items": [
								{
									name: 'route_id',
									type: 'RoutesCombo',
									label: 'Route',
									allowBlank: true,
									tooltip: 'Which route to display results for'
								},
								{
									name: 'city_id',
									type: 'CitiesCombo',
									label: 'City',
									allowBlank: true,
									tooltip: 'Which city to display results for',
								},
								{
									name: 'minPercentFull',
									type: 'PercentagesCombo',
									label: 'Min % Full',
									allowBlank: true,
									tooltip: 'The minimum percent full for each customer',
								},
							]
						},
					],
					startingValues: {
						startDate: Globals.dates.oneYearAgo,
						endDate: Globals.dates.today,
					},
				}}
				parent={self}
			/>;
}



function withAdditionalProps(WrappedComponent) {
	return (props) => {
		return <WrappedComponent
					reference="report2"
					{...props}
				/>;
	};
}
export default withAdditionalProps(withComponent(ReportComponent));
