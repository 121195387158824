/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function PermissionsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__user_id"
		                    },
		                    {
		                        "name": "permissions__group_id"
		                    },
		                    {
		                        "name": "permissions__view_reports"
		                    },
		                    {
		                        "name": "permissions__view_dashboard"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Cities",
		                "reference": "cities",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_cities"
		                    },
		                    {
		                        "name": "permissions__add_cities"
		                    },
		                    {
		                        "name": "permissions__edit_cities"
		                    },
		                    {
		                        "name": "permissions__delete_cities"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Collections",
		                "reference": "collections",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_collections"
		                    },
		                    {
		                        "name": "permissions__add_collections"
		                    },
		                    {
		                        "name": "permissions__edit_collections"
		                    },
		                    {
		                        "name": "permissions__delete_collections"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Collections Routes",
		                "reference": "collections_routes",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_collections_routes"
		                    },
		                    {
		                        "name": "permissions__add_collections_routes"
		                    },
		                    {
		                        "name": "permissions__edit_collections_routes"
		                    },
		                    {
		                        "name": "permissions__delete_collections_routes"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Customers",
		                "reference": "customers",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_customers"
		                    },
		                    {
		                        "name": "permissions__add_customers"
		                    },
		                    {
		                        "name": "permissions__edit_customers"
		                    },
		                    {
		                        "name": "permissions__delete_customers"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Customers Routes",
		                "reference": "customers_routes",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_customers_routes"
		                    },
		                    {
		                        "name": "permissions__add_customers_routes"
		                    },
		                    {
		                        "name": "permissions__edit_customers_routes"
		                    },
		                    {
		                        "name": "permissions__delete_customers_routes"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Drivers",
		                "reference": "drivers",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_drivers"
		                    },
		                    {
		                        "name": "permissions__add_drivers"
		                    },
		                    {
		                        "name": "permissions__edit_drivers"
		                    },
		                    {
		                        "name": "permissions__delete_drivers"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Groups",
		                "reference": "groups",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_groups"
		                    },
		                    {
		                        "name": "permissions__add_groups"
		                    },
		                    {
		                        "name": "permissions__edit_groups"
		                    },
		                    {
		                        "name": "permissions__delete_groups"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Groups Users",
		                "reference": "groups_users",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_groups_users"
		                    },
		                    {
		                        "name": "permissions__add_groups_users"
		                    },
		                    {
		                        "name": "permissions__edit_groups_users"
		                    },
		                    {
		                        "name": "permissions__delete_groups_users"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Permissions",
		                "reference": "permissions",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_permissions"
		                    },
		                    {
		                        "name": "permissions__add_permissions"
		                    },
		                    {
		                        "name": "permissions__edit_permissions"
		                    },
		                    {
		                        "name": "permissions__delete_permissions"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Pickups",
		                "reference": "pickups",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_pickups"
		                    },
		                    {
		                        "name": "permissions__add_pickups"
		                    },
		                    {
		                        "name": "permissions__edit_pickups"
		                    },
		                    {
		                        "name": "permissions__delete_pickups"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Routes",
		                "reference": "routes",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_routes"
		                    },
		                    {
		                        "name": "permissions__add_routes"
		                    },
		                    {
		                        "name": "permissions__edit_routes"
		                    },
		                    {
		                        "name": "permissions__delete_routes"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "States",
		                "reference": "states",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_states"
		                    },
		                    {
		                        "name": "permissions__add_states"
		                    },
		                    {
		                        "name": "permissions__edit_states"
		                    },
		                    {
		                        "name": "permissions__delete_states"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "users",
		                "reference": "users",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_users"
		                    },
		                    {
		                        "name": "permissions__add_users"
		                    },
		                    {
		                        "name": "permissions__edit_users"
		                    },
		                    {
		                        "name": "permissions__delete_users"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="PermissionsEditor"
				title="Permissions"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

