/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import DriversEditor from '../../Editor/DriversEditor.js';
import DriversGridColumns from '../../Grid/Columns/DriversGridColumns.js';

export default function DriversSideGridEditorPanel(props) {
	return <GridPanel
				reference="DriversSideGridEditorPanel"
				model="Drivers"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={DriversEditor}
				columnsConfig={DriversGridColumns}
				
				
				{...props}
			/>;
}