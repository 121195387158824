/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import RoutesInlineGridEditor from './RoutesInlineGridEditor.js';

export default function RoutesFilteredInlineGridEditor(props) {
	return <RoutesInlineGridEditor
				reference="RoutesFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}