/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function CustomersEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Specs",
		                "reference": "specs",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "customers__title"
		                    },
		                    {
		                        "name": "customers__contact"
		                    },
		                    {
		                        "name": "customers__routes"
		                    },
		                    {
		                        "name": "customers__price_code"
		                    },
		                    {
		                        "name": "customers__tank_capacity"
		                    },
		                    {
		                        "name": "customers__is_billable"
		                    },
		                    {
		                        "name": "customers__is_active"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Comments",
		                "reference": "comments",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "customers__pick_up_instructions"
		                    },
		                    {
		                        "name": "customers__comments"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Address",
		                "reference": "address",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "customers__street1"
		                    },
		                    {
		                        "name": "customers__street2"
		                    },
		                    {
		                        "name": "customers__city_id"
		                    },
		                    {
		                        "name": "customers__state_id"
		                    },
		                    {
		                        "name": "customers__zip"
		                    },
		                    {
		                        "name": "customers__phone"
		                    },
		                    {
		                        "name": "customers__fax"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Calc",
		                "reference": "calc",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "customers__avg_daily_gallons",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "customers__estimated_occupancy",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "customers__estimated_occupancy_percent",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [
		    {
		        "title": "Pickups",
		        "type": "PickupsGridEditor2", // MOD
		        "selectorId": "pickups__customer_id"
		    }
		],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="CustomersEditor"
				title="Customers"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

