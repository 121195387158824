/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import CollectionsRoutesSideGridEditor from './CollectionsRoutesSideGridEditor.js';

export default function CollectionsRoutesFilteredSideGridEditor(props) {
	return <CollectionsRoutesSideGridEditor
				reference="CollectionsRoutesFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}