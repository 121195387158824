import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Routes = {
	
	name: 'Routes',
	
	model: {

		idProperty: 'routes__id',
		displayProperty: 'routes__name',
		sortProperty: 'routes__name',
		
		sorters: null,

		validator: yup.object({
			routes__name: yup.string().required()
		}),
		
		properties: [
			{ name: 'routes__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'routes__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			belongsToMany: [
				'Customers',
				'Collections'
			],

		},

		ancillaryFilters: {
		    "customers": "Customer",
		    "collections": "Collection"
		},

		defaultFilters: [

		],

	},



	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Routes;
