export default [
	    {
	        "id": "customers__title",
	        "header": "Title",
	        "fieldName": "customers__title",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "customers__contact",
	        "header": "Contact",
	        "fieldName": "customers__contact",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "customers__routes",
	        "header": "Routes",
	        "fieldName": "customers__routes",
	        "isSortable": false,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 400 // MOD
	    },
	    {
	        "id": "customers__price_code",
	        "header": "Price Code",
	        "fieldName": "customers__price_code",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "customers__tank_capacity",
	        "header": "Tank Capacity",
	        "fieldName": "customers__tank_capacity",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "customers__is_billable",
	        "header": "Billable?",
	        "fieldName": "customers__is_billable",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "customers__is_active",
	        "header": "Active?",
	        "fieldName": "customers__is_active",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "customers__avg_daily_gallons",
	        "header": "Avg Daily Gallons",
	        "fieldName": "customers__avg_daily_gallons",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "customers__estimated_occupancy",
	        "header": "Estimated Occupancy",
	        "fieldName": "customers__estimated_occupancy",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "customers__estimated_occupancy_percent",
	        "header": "Current % Full",
	        "fieldName": "customers__estimated_occupancy_percent",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "customers__pick_up_instructions",
	        "header": "Pick Up Instructions",
	        "fieldName": "customers__pick_up_instructions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "customers__comments",
	        "header": "Comments",
	        "fieldName": "customers__comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "customers__street1",
	        "header": "Street1",
	        "fieldName": "customers__street1",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "customers__street2",
	        "header": "Street2",
	        "fieldName": "customers__street2",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "customers__city_id",
	        "header": "City",
	        "fieldName": "cities__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	        "editField": "customers__city_id"
	    },
	    {
	        "id": "customers__state_id",
	        "header": "State",
	        "fieldName": "states__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "customers__state_id"
	    },
	    {
	        "id": "customers__zip",
	        "header": "Zip",
	        "fieldName": "customers__zip",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "customers__phone",
	        "header": "Phone",
	        "fieldName": "customers__phone",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "customers__fax",
	        "header": "Fax",
	        "fieldName": "customers__fax",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    }
	];