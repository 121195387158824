import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Cities = {
	
	name: 'Cities',
	
	model: {

		idProperty: 'cities__id',
		displayProperty: 'cities__name',
		sortProperty: 'cities__name',
		
		sorters: null,

		validator: yup.object({
			cities__name: yup.string().max(50).nullable()
		}),
		
		properties: [
			{ name: 'cities__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'cities__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'Customers'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getCustomers: function() {
				const Customers = this.getAssociatedRepository('Customers');
				return Customers.getBy((entity) => {
					return entity.customers__city_id === this.cities__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": true
	},

};

export default Cities;
