/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import DriversSideGridEditorPanel from './DriversSideGridEditor.js';


export default function DriversFilteredSideGridEditorPanel(props) {
	return <DriversSideGridEditorPanel
				reference="DriversFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}