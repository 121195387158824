/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import CustomersRoutesEditorWindow from '../../Window/CustomersRoutesEditorWindow.js';
import CustomersRoutesGridColumns from '../../Grid/Columns/CustomersRoutesGridColumns.js';

export default function CustomersRoutesGridEditorPanel(props) {
	return <GridPanel
				reference="CustomersRoutesGridEditorPanel"
				model="CustomersRoutes"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={CustomersRoutesEditorWindow}
				columnsConfig={CustomersRoutesGridColumns}
				
				
				{...props}
			/>;
}