import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import CustomersFilteredGridEditor from '../components/Grid/CustomersFilteredGridEditor.js';
import CustomersFilteredSideGridEditor from '../components/Grid/CustomersFilteredSideGridEditor.js';

export default function CustomersManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'CustomersManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Customers"
				reference="CustomersManager"
				fullModeComponent={<CustomersFilteredGridEditor
										reference="CustomersGridEditor"
										useUploadDownload={true}
										useRemoteDuplicate={true}
									/>}
				sideModeComponent={<CustomersFilteredSideGridEditor
										reference="CustomersSideGridEditor"
										useUploadDownload={true}
										useRemoteDuplicate={true}
									/>}
			/>;
}
