/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PickupsInlineGridEditor from './PickupsInlineGridEditor.js';

export default function PickupsFilteredInlineGridEditor(props) {
	return <PickupsInlineGridEditor
				reference="PickupsFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}