/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import StatesEditor from '../Editor/StatesEditor.js';
import StatesGridColumns from './Columns/StatesGridColumns.js';

export default function StatesSideGridEditor(props) {
	return <SideGridEditor
				reference="StatesSideGridEditor"
				model="States"
				usePermissions={true}
				isCollapsible={false}
				Editor={StatesEditor}
				columnsConfig={StatesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}