/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import CustomersEditor from '../Editor/CustomersEditor.js';
import CustomersGridColumns from './Columns/CustomersGridColumns.js';

export default function CustomersSideGridEditor(props) {
	return <SideGridEditor
				reference="CustomersSideGridEditor"
				model="Customers"
				usePermissions={true}
				isCollapsible={false}
				Editor={CustomersEditor}
				columnsConfig={CustomersGridColumns}
				
				
				{...props}
			/>;
}