/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import registerComponents from '@onehat/ui/src/Functions/registerComponents.js';

import CitiesCombo from '../components/Form/Field/Combo/CitiesCombo.js';
import CitiesComboEditor from '../components/Form/Field/Combo/CitiesComboEditor.js';
import CitiesTag from '../components/Form/Field/Tag/CitiesTag.js';
import CitiesTagEditor from '../components/Form/Field/Tag/CitiesTagEditor.js';
import CitiesGrid from '../components/Grid/CitiesGrid.js';
import CitiesGridEditor from '../components/Grid/CitiesGridEditor.js';
import CitiesSideGridEditor from '../components/Grid/CitiesSideGridEditor.js';
import CitiesInlineGridEditor from '../components/Grid/CitiesInlineGridEditor.js';
import CitiesFilteredGrid from '../components/Grid/CitiesFilteredGrid.js';
import CitiesFilteredGridEditor from '../components/Grid/CitiesFilteredGridEditor.js';
import CitiesFilteredSideGridEditor from '../components/Grid/CitiesFilteredSideGridEditor.js';
import CitiesFilteredInlineGridEditor from '../components/Grid/CitiesFilteredInlineGridEditor.js';
import CitiesGridPanel from '../components/Panel/Grid/CitiesGrid.js';
import CitiesGridEditorPanel from '../components/Panel/Grid/CitiesGridEditor.js';
import CitiesSideGridEditorPanel from '../components/Panel/Grid/CitiesSideGridEditor.js';
import CitiesInlineGridEditorPanel from '../components/Panel/Grid/CitiesInlineGridEditor.js';
import CitiesFilteredGridPanel from '../components/Panel/Grid/CitiesFilteredGrid.js';
import CitiesFilteredGridEditorPanel from '../components/Panel/Grid/CitiesFilteredGridEditor.js';
import CitiesFilteredSideGridEditorPanel from '../components/Panel/Grid/CitiesFilteredSideGridEditor.js';
import CitiesFilteredInlineGridEditorPanel from '../components/Panel/Grid/CitiesFilteredInlineGridEditor.js';
import CitiesEditor from '../components/Editor/CitiesEditor.js';
import CitiesEditorWindow from '../components/Window/CitiesEditorWindow.js';
import CollectionsCombo from '../components/Form/Field/Combo/CollectionsCombo.js';
import CollectionsComboEditor from '../components/Form/Field/Combo/CollectionsComboEditor.js';
import CollectionsTag from '../components/Form/Field/Tag/CollectionsTag.js';
import CollectionsTagEditor from '../components/Form/Field/Tag/CollectionsTagEditor.js';
import CollectionsGrid from '../components/Grid/CollectionsGrid.js';
import CollectionsGridEditor from '../components/Grid/CollectionsGridEditor.js';
import CollectionsSideGridEditor from '../components/Grid/CollectionsSideGridEditor.js';
import CollectionsInlineGridEditor from '../components/Grid/CollectionsInlineGridEditor.js';
import CollectionsFilteredGrid from '../components/Grid/CollectionsFilteredGrid.js';
import CollectionsFilteredGridEditor from '../components/Grid/CollectionsFilteredGridEditor.js';
import CollectionsFilteredSideGridEditor from '../components/Grid/CollectionsFilteredSideGridEditor.js';
import CollectionsFilteredInlineGridEditor from '../components/Grid/CollectionsFilteredInlineGridEditor.js';
import CollectionsGridPanel from '../components/Panel/Grid/CollectionsGrid.js';
import CollectionsGridEditorPanel from '../components/Panel/Grid/CollectionsGridEditor.js';
import CollectionsSideGridEditorPanel from '../components/Panel/Grid/CollectionsSideGridEditor.js';
import CollectionsInlineGridEditorPanel from '../components/Panel/Grid/CollectionsInlineGridEditor.js';
import CollectionsFilteredGridPanel from '../components/Panel/Grid/CollectionsFilteredGrid.js';
import CollectionsFilteredGridEditorPanel from '../components/Panel/Grid/CollectionsFilteredGridEditor.js';
import CollectionsFilteredSideGridEditorPanel from '../components/Panel/Grid/CollectionsFilteredSideGridEditor.js';
import CollectionsFilteredInlineGridEditorPanel from '../components/Panel/Grid/CollectionsFilteredInlineGridEditor.js';
import CollectionsEditor from '../components/Editor/CollectionsEditor.js';
import CollectionsEditorWindow from '../components/Window/CollectionsEditorWindow.js';
import CollectionsRoutesCombo from '../components/Form/Field/Combo/CollectionsRoutesCombo.js';
import CollectionsRoutesComboEditor from '../components/Form/Field/Combo/CollectionsRoutesComboEditor.js';
import CollectionsRoutesTag from '../components/Form/Field/Tag/CollectionsRoutesTag.js';
import CollectionsRoutesTagEditor from '../components/Form/Field/Tag/CollectionsRoutesTagEditor.js';
import CollectionsRoutesGrid from '../components/Grid/CollectionsRoutesGrid.js';
import CollectionsRoutesGridEditor from '../components/Grid/CollectionsRoutesGridEditor.js';
import CollectionsRoutesSideGridEditor from '../components/Grid/CollectionsRoutesSideGridEditor.js';
import CollectionsRoutesInlineGridEditor from '../components/Grid/CollectionsRoutesInlineGridEditor.js';
import CollectionsRoutesFilteredGrid from '../components/Grid/CollectionsRoutesFilteredGrid.js';
import CollectionsRoutesFilteredGridEditor from '../components/Grid/CollectionsRoutesFilteredGridEditor.js';
import CollectionsRoutesFilteredSideGridEditor from '../components/Grid/CollectionsRoutesFilteredSideGridEditor.js';
import CollectionsRoutesFilteredInlineGridEditor from '../components/Grid/CollectionsRoutesFilteredInlineGridEditor.js';
import CollectionsRoutesGridPanel from '../components/Panel/Grid/CollectionsRoutesGrid.js';
import CollectionsRoutesGridEditorPanel from '../components/Panel/Grid/CollectionsRoutesGridEditor.js';
import CollectionsRoutesSideGridEditorPanel from '../components/Panel/Grid/CollectionsRoutesSideGridEditor.js';
import CollectionsRoutesInlineGridEditorPanel from '../components/Panel/Grid/CollectionsRoutesInlineGridEditor.js';
import CollectionsRoutesFilteredGridPanel from '../components/Panel/Grid/CollectionsRoutesFilteredGrid.js';
import CollectionsRoutesFilteredGridEditorPanel from '../components/Panel/Grid/CollectionsRoutesFilteredGridEditor.js';
import CollectionsRoutesFilteredSideGridEditorPanel from '../components/Panel/Grid/CollectionsRoutesFilteredSideGridEditor.js';
import CollectionsRoutesFilteredInlineGridEditorPanel from '../components/Panel/Grid/CollectionsRoutesFilteredInlineGridEditor.js';
import CollectionsRoutesEditor from '../components/Editor/CollectionsRoutesEditor.js';
import CollectionsRoutesEditorWindow from '../components/Window/CollectionsRoutesEditorWindow.js';
import CollectionsRoutesGridSideA from '../components/Grid/CollectionsRoutesGridSideA.js';
import CollectionsRoutesGridSideB from '../components/Grid/CollectionsRoutesGridSideB.js';
import CollectionsRoutesGridEditorSideA from '../components/Grid/CollectionsRoutesGridEditorSideA.js';
import CollectionsRoutesGridEditorSideB from '../components/Grid/CollectionsRoutesGridEditorSideB.js';
import CollectionsRoutesFilteredGridEditorSideA from '../components/Grid/CollectionsRoutesFilteredGridEditorSideA.js';
import CollectionsRoutesFilteredGridEditorSideB from '../components/Grid/CollectionsRoutesFilteredGridEditorSideB.js';
import CollectionsRoutesEditorWindowSideA from '../components/Window/CollectionsRoutesEditorWindowSideA.js';
import CollectionsRoutesEditorWindowSideB from '../components/Window/CollectionsRoutesEditorWindowSideB.js';
import CollectionsRoutesEditorSideA from '../components/Editor/CollectionsRoutesEditorSideA.js';
import CollectionsRoutesEditorSideB from '../components/Editor/CollectionsRoutesEditorSideB.js';
import CustomersCombo from '../components/Form/Field/Combo/CustomersCombo.js';
import CustomersComboEditor from '../components/Form/Field/Combo/CustomersComboEditor.js';
import CustomersTag from '../components/Form/Field/Tag/CustomersTag.js';
import CustomersTagEditor from '../components/Form/Field/Tag/CustomersTagEditor.js';
import CustomersGrid from '../components/Grid/CustomersGrid.js';
import CustomersGridEditor from '../components/Grid/CustomersGridEditor.js';
import CustomersSideGridEditor from '../components/Grid/CustomersSideGridEditor.js';
import CustomersInlineGridEditor from '../components/Grid/CustomersInlineGridEditor.js';
import CustomersFilteredGrid from '../components/Grid/CustomersFilteredGrid.js';
import CustomersFilteredGridEditor from '../components/Grid/CustomersFilteredGridEditor.js';
import CustomersFilteredSideGridEditor from '../components/Grid/CustomersFilteredSideGridEditor.js';
import CustomersFilteredInlineGridEditor from '../components/Grid/CustomersFilteredInlineGridEditor.js';
import CustomersGridPanel from '../components/Panel/Grid/CustomersGrid.js';
import CustomersGridEditorPanel from '../components/Panel/Grid/CustomersGridEditor.js';
import CustomersSideGridEditorPanel from '../components/Panel/Grid/CustomersSideGridEditor.js';
import CustomersInlineGridEditorPanel from '../components/Panel/Grid/CustomersInlineGridEditor.js';
import CustomersFilteredGridPanel from '../components/Panel/Grid/CustomersFilteredGrid.js';
import CustomersFilteredGridEditorPanel from '../components/Panel/Grid/CustomersFilteredGridEditor.js';
import CustomersFilteredSideGridEditorPanel from '../components/Panel/Grid/CustomersFilteredSideGridEditor.js';
import CustomersFilteredInlineGridEditorPanel from '../components/Panel/Grid/CustomersFilteredInlineGridEditor.js';
import CustomersEditor from '../components/Editor/CustomersEditor.js';
import CustomersEditorWindow from '../components/Window/CustomersEditorWindow.js';
import CustomersRoutesCombo from '../components/Form/Field/Combo/CustomersRoutesCombo.js';
import CustomersRoutesComboEditor from '../components/Form/Field/Combo/CustomersRoutesComboEditor.js';
import CustomersRoutesTag from '../components/Form/Field/Tag/CustomersRoutesTag.js';
import CustomersRoutesTagEditor from '../components/Form/Field/Tag/CustomersRoutesTagEditor.js';
import CustomersRoutesGrid from '../components/Grid/CustomersRoutesGrid.js';
import CustomersRoutesGridEditor from '../components/Grid/CustomersRoutesGridEditor.js';
import CustomersRoutesSideGridEditor from '../components/Grid/CustomersRoutesSideGridEditor.js';
import CustomersRoutesInlineGridEditor from '../components/Grid/CustomersRoutesInlineGridEditor.js';
import CustomersRoutesFilteredGrid from '../components/Grid/CustomersRoutesFilteredGrid.js';
import CustomersRoutesFilteredGridEditor from '../components/Grid/CustomersRoutesFilteredGridEditor.js';
import CustomersRoutesFilteredSideGridEditor from '../components/Grid/CustomersRoutesFilteredSideGridEditor.js';
import CustomersRoutesFilteredInlineGridEditor from '../components/Grid/CustomersRoutesFilteredInlineGridEditor.js';
import CustomersRoutesGridPanel from '../components/Panel/Grid/CustomersRoutesGrid.js';
import CustomersRoutesGridEditorPanel from '../components/Panel/Grid/CustomersRoutesGridEditor.js';
import CustomersRoutesSideGridEditorPanel from '../components/Panel/Grid/CustomersRoutesSideGridEditor.js';
import CustomersRoutesInlineGridEditorPanel from '../components/Panel/Grid/CustomersRoutesInlineGridEditor.js';
import CustomersRoutesFilteredGridPanel from '../components/Panel/Grid/CustomersRoutesFilteredGrid.js';
import CustomersRoutesFilteredGridEditorPanel from '../components/Panel/Grid/CustomersRoutesFilteredGridEditor.js';
import CustomersRoutesFilteredSideGridEditorPanel from '../components/Panel/Grid/CustomersRoutesFilteredSideGridEditor.js';
import CustomersRoutesFilteredInlineGridEditorPanel from '../components/Panel/Grid/CustomersRoutesFilteredInlineGridEditor.js';
import CustomersRoutesEditor from '../components/Editor/CustomersRoutesEditor.js';
import CustomersRoutesEditorWindow from '../components/Window/CustomersRoutesEditorWindow.js';
import CustomersRoutesGridSideA from '../components/Grid/CustomersRoutesGridSideA.js';
import CustomersRoutesGridSideB from '../components/Grid/CustomersRoutesGridSideB.js';
import CustomersRoutesGridEditorSideA from '../components/Grid/CustomersRoutesGridEditorSideA.js';
import CustomersRoutesGridEditorSideB from '../components/Grid/CustomersRoutesGridEditorSideB.js';
import CustomersRoutesFilteredGridEditorSideA from '../components/Grid/CustomersRoutesFilteredGridEditorSideA.js';
import CustomersRoutesFilteredGridEditorSideB from '../components/Grid/CustomersRoutesFilteredGridEditorSideB.js';
import CustomersRoutesEditorWindowSideA from '../components/Window/CustomersRoutesEditorWindowSideA.js';
import CustomersRoutesEditorWindowSideB from '../components/Window/CustomersRoutesEditorWindowSideB.js';
import CustomersRoutesEditorSideA from '../components/Editor/CustomersRoutesEditorSideA.js';
import CustomersRoutesEditorSideB from '../components/Editor/CustomersRoutesEditorSideB.js';
import DriversCombo from '../components/Form/Field/Combo/DriversCombo.js';
import DriversComboEditor from '../components/Form/Field/Combo/DriversComboEditor.js';
import DriversTag from '../components/Form/Field/Tag/DriversTag.js';
import DriversTagEditor from '../components/Form/Field/Tag/DriversTagEditor.js';
import DriversGrid from '../components/Grid/DriversGrid.js';
import DriversGridEditor from '../components/Grid/DriversGridEditor.js';
import DriversSideGridEditor from '../components/Grid/DriversSideGridEditor.js';
import DriversInlineGridEditor from '../components/Grid/DriversInlineGridEditor.js';
import DriversFilteredGrid from '../components/Grid/DriversFilteredGrid.js';
import DriversFilteredGridEditor from '../components/Grid/DriversFilteredGridEditor.js';
import DriversFilteredSideGridEditor from '../components/Grid/DriversFilteredSideGridEditor.js';
import DriversFilteredInlineGridEditor from '../components/Grid/DriversFilteredInlineGridEditor.js';
import DriversGridPanel from '../components/Panel/Grid/DriversGrid.js';
import DriversGridEditorPanel from '../components/Panel/Grid/DriversGridEditor.js';
import DriversSideGridEditorPanel from '../components/Panel/Grid/DriversSideGridEditor.js';
import DriversInlineGridEditorPanel from '../components/Panel/Grid/DriversInlineGridEditor.js';
import DriversFilteredGridPanel from '../components/Panel/Grid/DriversFilteredGrid.js';
import DriversFilteredGridEditorPanel from '../components/Panel/Grid/DriversFilteredGridEditor.js';
import DriversFilteredSideGridEditorPanel from '../components/Panel/Grid/DriversFilteredSideGridEditor.js';
import DriversFilteredInlineGridEditorPanel from '../components/Panel/Grid/DriversFilteredInlineGridEditor.js';
import DriversEditor from '../components/Editor/DriversEditor.js';
import DriversEditorWindow from '../components/Window/DriversEditorWindow.js';
import GroupsCombo from '../components/Form/Field/Combo/GroupsCombo.js';
import GroupsComboEditor from '../components/Form/Field/Combo/GroupsComboEditor.js';
import GroupsTag from '../components/Form/Field/Tag/GroupsTag.js';
import GroupsTagEditor from '../components/Form/Field/Tag/GroupsTagEditor.js';
import GroupsGrid from '../components/Grid/GroupsGrid.js';
import GroupsGridEditor from '../components/Grid/GroupsGridEditor.js';
import GroupsSideGridEditor from '../components/Grid/GroupsSideGridEditor.js';
import GroupsInlineGridEditor from '../components/Grid/GroupsInlineGridEditor.js';
import GroupsFilteredGrid from '../components/Grid/GroupsFilteredGrid.js';
import GroupsFilteredGridEditor from '../components/Grid/GroupsFilteredGridEditor.js';
import GroupsFilteredSideGridEditor from '../components/Grid/GroupsFilteredSideGridEditor.js';
import GroupsFilteredInlineGridEditor from '../components/Grid/GroupsFilteredInlineGridEditor.js';
import GroupsGridPanel from '../components/Panel/Grid/GroupsGrid.js';
import GroupsGridEditorPanel from '../components/Panel/Grid/GroupsGridEditor.js';
import GroupsSideGridEditorPanel from '../components/Panel/Grid/GroupsSideGridEditor.js';
import GroupsInlineGridEditorPanel from '../components/Panel/Grid/GroupsInlineGridEditor.js';
import GroupsFilteredGridPanel from '../components/Panel/Grid/GroupsFilteredGrid.js';
import GroupsFilteredGridEditorPanel from '../components/Panel/Grid/GroupsFilteredGridEditor.js';
import GroupsFilteredSideGridEditorPanel from '../components/Panel/Grid/GroupsFilteredSideGridEditor.js';
import GroupsFilteredInlineGridEditorPanel from '../components/Panel/Grid/GroupsFilteredInlineGridEditor.js';
import GroupsEditor from '../components/Editor/GroupsEditor.js';
import GroupsEditorWindow from '../components/Window/GroupsEditorWindow.js';
import GroupsUsersCombo from '../components/Form/Field/Combo/GroupsUsersCombo.js';
import GroupsUsersComboEditor from '../components/Form/Field/Combo/GroupsUsersComboEditor.js';
import GroupsUsersTag from '../components/Form/Field/Tag/GroupsUsersTag.js';
import GroupsUsersTagEditor from '../components/Form/Field/Tag/GroupsUsersTagEditor.js';
import GroupsUsersGrid from '../components/Grid/GroupsUsersGrid.js';
import GroupsUsersGridEditor from '../components/Grid/GroupsUsersGridEditor.js';
import GroupsUsersSideGridEditor from '../components/Grid/GroupsUsersSideGridEditor.js';
import GroupsUsersInlineGridEditor from '../components/Grid/GroupsUsersInlineGridEditor.js';
import GroupsUsersFilteredGrid from '../components/Grid/GroupsUsersFilteredGrid.js';
import GroupsUsersFilteredGridEditor from '../components/Grid/GroupsUsersFilteredGridEditor.js';
import GroupsUsersFilteredSideGridEditor from '../components/Grid/GroupsUsersFilteredSideGridEditor.js';
import GroupsUsersFilteredInlineGridEditor from '../components/Grid/GroupsUsersFilteredInlineGridEditor.js';
import GroupsUsersGridPanel from '../components/Panel/Grid/GroupsUsersGrid.js';
import GroupsUsersGridEditorPanel from '../components/Panel/Grid/GroupsUsersGridEditor.js';
import GroupsUsersSideGridEditorPanel from '../components/Panel/Grid/GroupsUsersSideGridEditor.js';
import GroupsUsersInlineGridEditorPanel from '../components/Panel/Grid/GroupsUsersInlineGridEditor.js';
import GroupsUsersFilteredGridPanel from '../components/Panel/Grid/GroupsUsersFilteredGrid.js';
import GroupsUsersFilteredGridEditorPanel from '../components/Panel/Grid/GroupsUsersFilteredGridEditor.js';
import GroupsUsersFilteredSideGridEditorPanel from '../components/Panel/Grid/GroupsUsersFilteredSideGridEditor.js';
import GroupsUsersFilteredInlineGridEditorPanel from '../components/Panel/Grid/GroupsUsersFilteredInlineGridEditor.js';
import GroupsUsersEditor from '../components/Editor/GroupsUsersEditor.js';
import GroupsUsersEditorWindow from '../components/Window/GroupsUsersEditorWindow.js';
import GroupsUsersGridSideA from '../components/Grid/GroupsUsersGridSideA.js';
import GroupsUsersGridSideB from '../components/Grid/GroupsUsersGridSideB.js';
import GroupsUsersGridEditorSideA from '../components/Grid/GroupsUsersGridEditorSideA.js';
import GroupsUsersGridEditorSideB from '../components/Grid/GroupsUsersGridEditorSideB.js';
import GroupsUsersFilteredGridEditorSideA from '../components/Grid/GroupsUsersFilteredGridEditorSideA.js';
import GroupsUsersFilteredGridEditorSideB from '../components/Grid/GroupsUsersFilteredGridEditorSideB.js';
import GroupsUsersEditorWindowSideA from '../components/Window/GroupsUsersEditorWindowSideA.js';
import GroupsUsersEditorWindowSideB from '../components/Window/GroupsUsersEditorWindowSideB.js';
import GroupsUsersEditorSideA from '../components/Editor/GroupsUsersEditorSideA.js';
import GroupsUsersEditorSideB from '../components/Editor/GroupsUsersEditorSideB.js';
import PercentagesCombo from '../components/Form/Field/Combo/PercentagesCombo.js';
import PermissionsCombo from '../components/Form/Field/Combo/PermissionsCombo.js';
import PermissionsComboEditor from '../components/Form/Field/Combo/PermissionsComboEditor.js';
import PermissionsTag from '../components/Form/Field/Tag/PermissionsTag.js';
import PermissionsTagEditor from '../components/Form/Field/Tag/PermissionsTagEditor.js';
import PermissionsGrid from '../components/Grid/PermissionsGrid.js';
import PermissionsGridEditor from '../components/Grid/PermissionsGridEditor.js';
import PermissionsSideGridEditor from '../components/Grid/PermissionsSideGridEditor.js';
import PermissionsInlineGridEditor from '../components/Grid/PermissionsInlineGridEditor.js';
import PermissionsFilteredGrid from '../components/Grid/PermissionsFilteredGrid.js';
import PermissionsFilteredGridEditor from '../components/Grid/PermissionsFilteredGridEditor.js';
import PermissionsFilteredSideGridEditor from '../components/Grid/PermissionsFilteredSideGridEditor.js';
import PermissionsFilteredInlineGridEditor from '../components/Grid/PermissionsFilteredInlineGridEditor.js';
import PermissionsGridPanel from '../components/Panel/Grid/PermissionsGrid.js';
import PermissionsGridEditorPanel from '../components/Panel/Grid/PermissionsGridEditor.js';
import PermissionsSideGridEditorPanel from '../components/Panel/Grid/PermissionsSideGridEditor.js';
import PermissionsInlineGridEditorPanel from '../components/Panel/Grid/PermissionsInlineGridEditor.js';
import PermissionsFilteredGridPanel from '../components/Panel/Grid/PermissionsFilteredGrid.js';
import PermissionsFilteredGridEditorPanel from '../components/Panel/Grid/PermissionsFilteredGridEditor.js';
import PermissionsFilteredSideGridEditorPanel from '../components/Panel/Grid/PermissionsFilteredSideGridEditor.js';
import PermissionsFilteredInlineGridEditorPanel from '../components/Panel/Grid/PermissionsFilteredInlineGridEditor.js';
import PermissionsEditor from '../components/Editor/PermissionsEditor.js';
import PermissionsEditorWindow from '../components/Window/PermissionsEditorWindow.js';
import PickupsCombo from '../components/Form/Field/Combo/PickupsCombo.js';
import PickupsComboEditor from '../components/Form/Field/Combo/PickupsComboEditor.js';
import PickupsTag from '../components/Form/Field/Tag/PickupsTag.js';
import PickupsTagEditor from '../components/Form/Field/Tag/PickupsTagEditor.js';
import PickupsGrid from '../components/Grid/PickupsGrid.js';
import PickupsGridEditor from '../components/Grid/PickupsGridEditor.js';
import PickupsGridEditor2 from '../components/Grid/PickupsGridEditor2.js';
import PickupsSideGridEditor from '../components/Grid/PickupsSideGridEditor.js';
import PickupsInlineGridEditor from '../components/Grid/PickupsInlineGridEditor.js';
import PickupsFilteredGrid from '../components/Grid/PickupsFilteredGrid.js';
import PickupsFilteredGridEditor from '../components/Grid/PickupsFilteredGridEditor.js';
import PickupsFilteredSideGridEditor from '../components/Grid/PickupsFilteredSideGridEditor.js';
import PickupsFilteredInlineGridEditor from '../components/Grid/PickupsFilteredInlineGridEditor.js';
import PickupsGridPanel from '../components/Panel/Grid/PickupsGrid.js';
import PickupsGridEditorPanel from '../components/Panel/Grid/PickupsGridEditor.js';
import PickupsSideGridEditorPanel from '../components/Panel/Grid/PickupsSideGridEditor.js';
import PickupsInlineGridEditorPanel from '../components/Panel/Grid/PickupsInlineGridEditor.js';
import PickupsFilteredGridPanel from '../components/Panel/Grid/PickupsFilteredGrid.js';
import PickupsFilteredGridEditorPanel from '../components/Panel/Grid/PickupsFilteredGridEditor.js';
import PickupsFilteredSideGridEditorPanel from '../components/Panel/Grid/PickupsFilteredSideGridEditor.js';
import PickupsFilteredInlineGridEditorPanel from '../components/Panel/Grid/PickupsFilteredInlineGridEditor.js';
import PickupsEditor from '../components/Editor/PickupsEditor.js';
import PickupsEditorWindow from '../components/Window/PickupsEditorWindow.js';
import RoutesCombo from '../components/Form/Field/Combo/RoutesCombo.js';
import RoutesComboEditor from '../components/Form/Field/Combo/RoutesComboEditor.js';
import RoutesTag from '../components/Form/Field/Tag/RoutesTag.js';
import RoutesTagEditor from '../components/Form/Field/Tag/RoutesTagEditor.js';
import RoutesGrid from '../components/Grid/RoutesGrid.js';
import RoutesGridEditor from '../components/Grid/RoutesGridEditor.js';
import RoutesSideGridEditor from '../components/Grid/RoutesSideGridEditor.js';
import RoutesInlineGridEditor from '../components/Grid/RoutesInlineGridEditor.js';
import RoutesFilteredGrid from '../components/Grid/RoutesFilteredGrid.js';
import RoutesFilteredGridEditor from '../components/Grid/RoutesFilteredGridEditor.js';
import RoutesFilteredSideGridEditor from '../components/Grid/RoutesFilteredSideGridEditor.js';
import RoutesFilteredInlineGridEditor from '../components/Grid/RoutesFilteredInlineGridEditor.js';
import RoutesGridPanel from '../components/Panel/Grid/RoutesGrid.js';
import RoutesGridEditorPanel from '../components/Panel/Grid/RoutesGridEditor.js';
import RoutesSideGridEditorPanel from '../components/Panel/Grid/RoutesSideGridEditor.js';
import RoutesInlineGridEditorPanel from '../components/Panel/Grid/RoutesInlineGridEditor.js';
import RoutesFilteredGridPanel from '../components/Panel/Grid/RoutesFilteredGrid.js';
import RoutesFilteredGridEditorPanel from '../components/Panel/Grid/RoutesFilteredGridEditor.js';
import RoutesFilteredSideGridEditorPanel from '../components/Panel/Grid/RoutesFilteredSideGridEditor.js';
import RoutesFilteredInlineGridEditorPanel from '../components/Panel/Grid/RoutesFilteredInlineGridEditor.js';
import RoutesEditor from '../components/Editor/RoutesEditor.js';
import RoutesEditorWindow from '../components/Window/RoutesEditorWindow.js';
import StatesCombo from '../components/Form/Field/Combo/StatesCombo.js';
import StatesComboEditor from '../components/Form/Field/Combo/StatesComboEditor.js';
import StatesTag from '../components/Form/Field/Tag/StatesTag.js';
import StatesTagEditor from '../components/Form/Field/Tag/StatesTagEditor.js';
import StatesGrid from '../components/Grid/StatesGrid.js';
import StatesGridEditor from '../components/Grid/StatesGridEditor.js';
import StatesSideGridEditor from '../components/Grid/StatesSideGridEditor.js';
import StatesInlineGridEditor from '../components/Grid/StatesInlineGridEditor.js';
import StatesFilteredGrid from '../components/Grid/StatesFilteredGrid.js';
import StatesFilteredGridEditor from '../components/Grid/StatesFilteredGridEditor.js';
import StatesFilteredSideGridEditor from '../components/Grid/StatesFilteredSideGridEditor.js';
import StatesFilteredInlineGridEditor from '../components/Grid/StatesFilteredInlineGridEditor.js';
import StatesGridPanel from '../components/Panel/Grid/StatesGrid.js';
import StatesGridEditorPanel from '../components/Panel/Grid/StatesGridEditor.js';
import StatesSideGridEditorPanel from '../components/Panel/Grid/StatesSideGridEditor.js';
import StatesInlineGridEditorPanel from '../components/Panel/Grid/StatesInlineGridEditor.js';
import StatesFilteredGridPanel from '../components/Panel/Grid/StatesFilteredGrid.js';
import StatesFilteredGridEditorPanel from '../components/Panel/Grid/StatesFilteredGridEditor.js';
import StatesFilteredSideGridEditorPanel from '../components/Panel/Grid/StatesFilteredSideGridEditor.js';
import StatesFilteredInlineGridEditorPanel from '../components/Panel/Grid/StatesFilteredInlineGridEditor.js';
import StatesEditor from '../components/Editor/StatesEditor.js';
import StatesEditorWindow from '../components/Window/StatesEditorWindow.js';
import UsersCombo from '../components/Form/Field/Combo/UsersCombo.js';
import UsersComboEditor from '../components/Form/Field/Combo/UsersComboEditor.js';
import UsersTag from '../components/Form/Field/Tag/UsersTag.js';
import UsersTagEditor from '../components/Form/Field/Tag/UsersTagEditor.js';
import UsersGrid from '../components/Grid/UsersGrid.js';
import UsersGridEditor from '../components/Grid/UsersGridEditor.js';
import UsersSideGridEditor from '../components/Grid/UsersSideGridEditor.js';
import UsersInlineGridEditor from '../components/Grid/UsersInlineGridEditor.js';
import UsersFilteredGrid from '../components/Grid/UsersFilteredGrid.js';
import UsersFilteredGridEditor from '../components/Grid/UsersFilteredGridEditor.js';
import UsersFilteredSideGridEditor from '../components/Grid/UsersFilteredSideGridEditor.js';
import UsersFilteredInlineGridEditor from '../components/Grid/UsersFilteredInlineGridEditor.js';
import UsersGridPanel from '../components/Panel/Grid/UsersGrid.js';
import UsersGridEditorPanel from '../components/Panel/Grid/UsersGridEditor.js';
import UsersSideGridEditorPanel from '../components/Panel/Grid/UsersSideGridEditor.js';
import UsersInlineGridEditorPanel from '../components/Panel/Grid/UsersInlineGridEditor.js';
import UsersFilteredGridPanel from '../components/Panel/Grid/UsersFilteredGrid.js';
import UsersFilteredGridEditorPanel from '../components/Panel/Grid/UsersFilteredGridEditor.js';
import UsersFilteredSideGridEditorPanel from '../components/Panel/Grid/UsersFilteredSideGridEditor.js';
import UsersFilteredInlineGridEditorPanel from '../components/Panel/Grid/UsersFilteredInlineGridEditor.js';
import UsersEditor from '../components/Editor/UsersEditor.js';
import UsersEditorWindow from '../components/Window/UsersEditorWindow.js';

export default function registerComponentsFn() {

	registerComponents({
		CitiesCombo,
		CitiesComboEditor,
		CitiesTag,
		CitiesTagEditor,
		CitiesGrid,
		CitiesGridEditor,
		CitiesSideGridEditor,
		CitiesInlineGridEditor,
		CitiesFilteredGrid,
		CitiesFilteredGridEditor,
		CitiesFilteredSideGridEditor,
		CitiesFilteredInlineGridEditor,
		CitiesGridPanel,
		CitiesGridEditorPanel,
		CitiesSideGridEditorPanel,
		CitiesInlineGridEditorPanel,
		CitiesFilteredGridPanel,
		CitiesFilteredGridEditorPanel,
		CitiesFilteredSideGridEditorPanel,
		CitiesFilteredInlineGridEditorPanel,
		CitiesEditor,
		CitiesEditorWindow,
		CollectionsCombo,
		CollectionsComboEditor,
		CollectionsTag,
		CollectionsTagEditor,
		CollectionsGrid,
		CollectionsGridEditor,
		CollectionsSideGridEditor,
		CollectionsInlineGridEditor,
		CollectionsFilteredGrid,
		CollectionsFilteredGridEditor,
		CollectionsFilteredSideGridEditor,
		CollectionsFilteredInlineGridEditor,
		CollectionsGridPanel,
		CollectionsGridEditorPanel,
		CollectionsSideGridEditorPanel,
		CollectionsInlineGridEditorPanel,
		CollectionsFilteredGridPanel,
		CollectionsFilteredGridEditorPanel,
		CollectionsFilteredSideGridEditorPanel,
		CollectionsFilteredInlineGridEditorPanel,
		CollectionsEditor,
		CollectionsEditorWindow,
		CollectionsRoutesCombo,
		CollectionsRoutesComboEditor,
		CollectionsRoutesTag,
		CollectionsRoutesTagEditor,
		CollectionsRoutesGrid,
		CollectionsRoutesGridEditor,
		CollectionsRoutesSideGridEditor,
		CollectionsRoutesInlineGridEditor,
		CollectionsRoutesFilteredGrid,
		CollectionsRoutesFilteredGridEditor,
		CollectionsRoutesFilteredSideGridEditor,
		CollectionsRoutesFilteredInlineGridEditor,
		CollectionsRoutesGridPanel,
		CollectionsRoutesGridEditorPanel,
		CollectionsRoutesSideGridEditorPanel,
		CollectionsRoutesInlineGridEditorPanel,
		CollectionsRoutesFilteredGridPanel,
		CollectionsRoutesFilteredGridEditorPanel,
		CollectionsRoutesFilteredSideGridEditorPanel,
		CollectionsRoutesFilteredInlineGridEditorPanel,
		CollectionsRoutesEditor,
		CollectionsRoutesEditorWindow,
		CollectionsRoutesGridSideA,
		CollectionsRoutesGridSideB,
		CollectionsRoutesGridEditorSideA,
		CollectionsRoutesGridEditorSideB,
		CollectionsRoutesFilteredGridEditorSideA,
		CollectionsRoutesFilteredGridEditorSideB,
		CollectionsRoutesEditorWindowSideA,
		CollectionsRoutesEditorWindowSideB,
		CollectionsRoutesEditorSideA,
		CollectionsRoutesEditorSideB,
		CustomersCombo,
		CustomersComboEditor,
		CustomersTag,
		CustomersTagEditor,
		CustomersGrid,
		CustomersGridEditor,
		CustomersSideGridEditor,
		CustomersInlineGridEditor,
		CustomersFilteredGrid,
		CustomersFilteredGridEditor,
		CustomersFilteredSideGridEditor,
		CustomersFilteredInlineGridEditor,
		CustomersGridPanel,
		CustomersGridEditorPanel,
		CustomersSideGridEditorPanel,
		CustomersInlineGridEditorPanel,
		CustomersFilteredGridPanel,
		CustomersFilteredGridEditorPanel,
		CustomersFilteredSideGridEditorPanel,
		CustomersFilteredInlineGridEditorPanel,
		CustomersEditor,
		CustomersEditorWindow,
		CustomersRoutesCombo,
		CustomersRoutesComboEditor,
		CustomersRoutesTag,
		CustomersRoutesTagEditor,
		CustomersRoutesGrid,
		CustomersRoutesGridEditor,
		CustomersRoutesSideGridEditor,
		CustomersRoutesInlineGridEditor,
		CustomersRoutesFilteredGrid,
		CustomersRoutesFilteredGridEditor,
		CustomersRoutesFilteredSideGridEditor,
		CustomersRoutesFilteredInlineGridEditor,
		CustomersRoutesGridPanel,
		CustomersRoutesGridEditorPanel,
		CustomersRoutesSideGridEditorPanel,
		CustomersRoutesInlineGridEditorPanel,
		CustomersRoutesFilteredGridPanel,
		CustomersRoutesFilteredGridEditorPanel,
		CustomersRoutesFilteredSideGridEditorPanel,
		CustomersRoutesFilteredInlineGridEditorPanel,
		CustomersRoutesEditor,
		CustomersRoutesEditorWindow,
		CustomersRoutesGridSideA,
		CustomersRoutesGridSideB,
		CustomersRoutesGridEditorSideA,
		CustomersRoutesGridEditorSideB,
		CustomersRoutesFilteredGridEditorSideA,
		CustomersRoutesFilteredGridEditorSideB,
		CustomersRoutesEditorWindowSideA,
		CustomersRoutesEditorWindowSideB,
		CustomersRoutesEditorSideA,
		CustomersRoutesEditorSideB,
		DriversCombo,
		DriversComboEditor,
		DriversTag,
		DriversTagEditor,
		DriversGrid,
		DriversGridEditor,
		DriversSideGridEditor,
		DriversInlineGridEditor,
		DriversFilteredGrid,
		DriversFilteredGridEditor,
		DriversFilteredSideGridEditor,
		DriversFilteredInlineGridEditor,
		DriversGridPanel,
		DriversGridEditorPanel,
		DriversSideGridEditorPanel,
		DriversInlineGridEditorPanel,
		DriversFilteredGridPanel,
		DriversFilteredGridEditorPanel,
		DriversFilteredSideGridEditorPanel,
		DriversFilteredInlineGridEditorPanel,
		DriversEditor,
		DriversEditorWindow,
		GroupsCombo,
		GroupsComboEditor,
		GroupsTag,
		GroupsTagEditor,
		GroupsGrid,
		GroupsGridEditor,
		GroupsSideGridEditor,
		GroupsInlineGridEditor,
		GroupsFilteredGrid,
		GroupsFilteredGridEditor,
		GroupsFilteredSideGridEditor,
		GroupsFilteredInlineGridEditor,
		GroupsGridPanel,
		GroupsGridEditorPanel,
		GroupsSideGridEditorPanel,
		GroupsInlineGridEditorPanel,
		GroupsFilteredGridPanel,
		GroupsFilteredGridEditorPanel,
		GroupsFilteredSideGridEditorPanel,
		GroupsFilteredInlineGridEditorPanel,
		GroupsEditor,
		GroupsEditorWindow,
		GroupsUsersCombo,
		GroupsUsersComboEditor,
		GroupsUsersTag,
		GroupsUsersTagEditor,
		GroupsUsersGrid,
		GroupsUsersGridEditor,
		GroupsUsersSideGridEditor,
		GroupsUsersInlineGridEditor,
		GroupsUsersFilteredGrid,
		GroupsUsersFilteredGridEditor,
		GroupsUsersFilteredSideGridEditor,
		GroupsUsersFilteredInlineGridEditor,
		GroupsUsersGridPanel,
		GroupsUsersGridEditorPanel,
		GroupsUsersSideGridEditorPanel,
		GroupsUsersInlineGridEditorPanel,
		GroupsUsersFilteredGridPanel,
		GroupsUsersFilteredGridEditorPanel,
		GroupsUsersFilteredSideGridEditorPanel,
		GroupsUsersFilteredInlineGridEditorPanel,
		GroupsUsersEditor,
		GroupsUsersEditorWindow,
		GroupsUsersGridSideA,
		GroupsUsersGridSideB,
		GroupsUsersGridEditorSideA,
		GroupsUsersGridEditorSideB,
		GroupsUsersFilteredGridEditorSideA,
		GroupsUsersFilteredGridEditorSideB,
		GroupsUsersEditorWindowSideA,
		GroupsUsersEditorWindowSideB,
		GroupsUsersEditorSideA,
		GroupsUsersEditorSideB,
		PercentagesCombo,
		PermissionsCombo,
		PermissionsComboEditor,
		PermissionsTag,
		PermissionsTagEditor,
		PermissionsGrid,
		PermissionsGridEditor,
		PermissionsSideGridEditor,
		PermissionsInlineGridEditor,
		PermissionsFilteredGrid,
		PermissionsFilteredGridEditor,
		PermissionsFilteredSideGridEditor,
		PermissionsFilteredInlineGridEditor,
		PermissionsGridPanel,
		PermissionsGridEditorPanel,
		PermissionsSideGridEditorPanel,
		PermissionsInlineGridEditorPanel,
		PermissionsFilteredGridPanel,
		PermissionsFilteredGridEditorPanel,
		PermissionsFilteredSideGridEditorPanel,
		PermissionsFilteredInlineGridEditorPanel,
		PermissionsEditor,
		PermissionsEditorWindow,
		PickupsCombo,
		PickupsComboEditor,
		PickupsTag,
		PickupsTagEditor,
		PickupsGrid,
		PickupsGridEditor,
		PickupsGridEditor2,
		PickupsSideGridEditor,
		PickupsInlineGridEditor,
		PickupsFilteredGrid,
		PickupsFilteredGridEditor,
		PickupsFilteredSideGridEditor,
		PickupsFilteredInlineGridEditor,
		PickupsGridPanel,
		PickupsGridEditorPanel,
		PickupsSideGridEditorPanel,
		PickupsInlineGridEditorPanel,
		PickupsFilteredGridPanel,
		PickupsFilteredGridEditorPanel,
		PickupsFilteredSideGridEditorPanel,
		PickupsFilteredInlineGridEditorPanel,
		PickupsEditor,
		PickupsEditorWindow,
		RoutesCombo,
		RoutesComboEditor,
		RoutesTag,
		RoutesTagEditor,
		RoutesGrid,
		RoutesGridEditor,
		RoutesSideGridEditor,
		RoutesInlineGridEditor,
		RoutesFilteredGrid,
		RoutesFilteredGridEditor,
		RoutesFilteredSideGridEditor,
		RoutesFilteredInlineGridEditor,
		RoutesGridPanel,
		RoutesGridEditorPanel,
		RoutesSideGridEditorPanel,
		RoutesInlineGridEditorPanel,
		RoutesFilteredGridPanel,
		RoutesFilteredGridEditorPanel,
		RoutesFilteredSideGridEditorPanel,
		RoutesFilteredInlineGridEditorPanel,
		RoutesEditor,
		RoutesEditorWindow,
		StatesCombo,
		StatesComboEditor,
		StatesTag,
		StatesTagEditor,
		StatesGrid,
		StatesGridEditor,
		StatesSideGridEditor,
		StatesInlineGridEditor,
		StatesFilteredGrid,
		StatesFilteredGridEditor,
		StatesFilteredSideGridEditor,
		StatesFilteredInlineGridEditor,
		StatesGridPanel,
		StatesGridEditorPanel,
		StatesSideGridEditorPanel,
		StatesInlineGridEditorPanel,
		StatesFilteredGridPanel,
		StatesFilteredGridEditorPanel,
		StatesFilteredSideGridEditorPanel,
		StatesFilteredInlineGridEditorPanel,
		StatesEditor,
		StatesEditorWindow,
		UsersCombo,
		UsersComboEditor,
		UsersTag,
		UsersTagEditor,
		UsersGrid,
		UsersGridEditor,
		UsersSideGridEditor,
		UsersInlineGridEditor,
		UsersFilteredGrid,
		UsersFilteredGridEditor,
		UsersFilteredSideGridEditor,
		UsersFilteredInlineGridEditor,
		UsersGridPanel,
		UsersGridEditorPanel,
		UsersSideGridEditorPanel,
		UsersInlineGridEditorPanel,
		UsersFilteredGridPanel,
		UsersFilteredGridEditorPanel,
		UsersFilteredSideGridEditorPanel,
		UsersFilteredInlineGridEditorPanel,
		UsersEditor,
		UsersEditorWindow
	});
	
}
