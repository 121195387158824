export default [
	    // {
	    //     "id": "pickups__collection_id",
	    //     "header": "Collection",
	    //     "fieldName": "collections__date",
	    //     "isSortable": true,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 100,
	    //     "editField": "pickups__collection_id"
	    // },
	    {
	        "id": "pickups__sort_order",
	        "header": "Sort", // MOD
	        "fieldName": "pickups__sort_order",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "pickups__customer_id",
	        "header": "Customer",
	        "fieldName": "customers__title",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200, // MOD
	        "editField": "pickups__customer_id"
	    },
	    {
	        "id": "pickups__gallons_oil",
	        "header": "Gallons Oil",
	        "fieldName": "pickups__gallons_oil",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 120 // MOD
	    },
	    {
	        "id": "pickups__gallons_sludge",
	        "header": "Gallons Water / Sludge",
	        "fieldName": "pickups__gallons_sludge",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "pickups__gallons_left",
	        "header": "Gallons Left",
	        "fieldName": "pickups__gallons_left",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "pickups__comments",
	        "header": "Comments",
	        "fieldName": "pickups__comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "pickups__is_grease_trap_only",
	        "header": "Grease Trap Only?",
	        "fieldName": "pickups__is_grease_trap_only",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    }
	];