/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import CustomersGridColumns from '../../Grid/Columns/CustomersGridColumns.js';

export default function CustomersGridPanel(props) {
	return <GridPanel
				reference="CustomersGridPanel"
				model="Customers"
				usePermissions={true}
				columnsConfig={CustomersGridColumns}

				{...props}
			/>;
}