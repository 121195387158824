import * as yup from 'yup'; // https://github.com/jquense/yup#string

const CustomersRoutes = {
	
	name: 'CustomersRoutes',
	
	model: {

		idProperty: 'customers_routes__id',
		displayProperty: 'customers_routes__id',
		sortProperty: 'customers_routes__id',
		
		sorters: null,

		validator: yup.object({
			customers_routes__customer_id: yup.number().integer().required(),
			customers_routes__route_id: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'customers_routes__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'customers_routes__customer_id', mapping: 'customer_id', title: 'Customer', type: 'int', isFk: true, fkIdField: 'customers__id', fkDisplayField: 'customers__title', filterType: {"type":"CustomersCombo","loadAfterRender":!1}, editorType: {"type":"CustomersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'customers_routes__route_id', mapping: 'route_id', title: 'Route', type: 'int', isFk: true, fkIdField: 'routes__id', fkDisplayField: 'routes__name', filterType: {"type":"RoutesCombo","loadAfterRender":!1}, editorType: {"type":"RoutesComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'customers__id', mapping: 'customer.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__title', mapping: 'customer.title', title: 'Title', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__contact', mapping: 'customer.contact', title: 'Contact', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__routes', mapping: 'customer.routes', title: 'Routes', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__price_code', mapping: 'customer.price_code', title: 'Price Code', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__tank_capacity', mapping: 'customer.tank_capacity', title: 'Tank Capacity', type: 'float', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__is_billable', mapping: 'customer.is_billable', title: 'Billable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__is_active', mapping: 'customer.is_active', title: 'Active?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__is_stale_stats', mapping: 'customer.is_stale_stats', title: 'Stats Stale?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__avg_daily_gallons', mapping: 'customer.avg_daily_gallons', title: 'Avg Daily Gallons', type: 'float', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__estimated_occupancy', mapping: 'customer.estimated_occupancy', title: 'Estimated Occupancy', type: 'float', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__estimated_occupancy_percent', mapping: 'customer.estimated_occupancy_percent', title: 'Current % Full', type: 'percent', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__pick_up_instructions', mapping: 'customer.pick_up_instructions', title: 'Pick Up Instructions', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__comments', mapping: 'customer.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__street1', mapping: 'customer.street1', title: 'Street1', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__street2', mapping: 'customer.street2', title: 'Street2', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__city_id', mapping: 'customer.city_id', title: 'City', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__state_id', mapping: 'customer.state_id', title: 'State', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__zip', mapping: 'customer.zip', title: 'Zip', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__phone', mapping: 'customer.phone', title: 'Phone', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'customers__fax', mapping: 'customer.fax', title: 'Fax', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'routes__id', mapping: 'route.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'routes__name', mapping: 'route.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Customers',
				'Routes'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'customers_routes__customer_id',
			// 'customers_routes__route_id'
		],

	},

	entity: {
		methods: {

			getCustomer: async function() {
				const Customers = this.getAssociatedRepository('Customers');
				let entity = Customers.getById(this.customers_routes__customer_id);
				if (!entity) {
					entity = await Customers.getSingleEntityFromServer(this.customers_routes__customer_id);
				}
				return entity;
			},

			getRoute: async function() {
				const Routes = this.getAssociatedRepository('Routes');
				let entity = Routes.getById(this.customers_routes__route_id);
				if (!entity) {
					entity = await Routes.getSingleEntityFromServer(this.customers_routes__route_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default CustomersRoutes;
