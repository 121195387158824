/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import DriversInlineGridEditor from './DriversInlineGridEditor.js';

export default function DriversFilteredInlineGridEditor(props) {
	return <DriversInlineGridEditor
				reference="DriversFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}