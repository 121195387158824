/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import CustomersEditorWindow from '../../Window/CustomersEditorWindow.js';
import CustomersGridColumns from '../../Grid/Columns/CustomersGridColumns.js';

export default function CustomersGridEditorPanel(props) {
	return <GridPanel
				reference="CustomersGridEditorPanel"
				model="Customers"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={CustomersEditorWindow}
				columnsConfig={CustomersGridColumns}
				
				
				{...props}
			/>;
}