import * as yup from 'yup'; // https://github.com/jquense/yup#string
import AppGlobals from '../../AppGlobals';

const Collections = {
	
	name: 'Collections',
	
	model: {

		idProperty: 'collections__id',
		displayProperty: 'collections__date',
		sortProperty: 'collections__date',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({
			collections__date: yup.date().required(),
			collections__routes: yup.string().required(),
			collections__driver_id: yup.number().integer().nullable()
		}),
		
		properties: [
			{ name: 'collections__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'collections__date', mapping: 'date', title: 'Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', defaultValue: () => AppGlobals.dates.today, },
			{ name: 'collections__routes', mapping: 'routes', title: 'Routes', filterType: {"type":"RoutesTag"}, viewerType: {"type":"RoutesTag"}, editorType: {"type":"RoutesTagEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'collections__driver_id', mapping: 'driver_id', title: 'Driver', type: 'int', isFk: true, fkIdField: 'drivers__id', fkDisplayField: 'drivers__name', filterType: {"type":"DriversCombo","loadAfterRender":!1}, editorType: {"type":"DriversComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'drivers__id', mapping: 'driver.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'drivers__name', mapping: 'driver.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'Pickups'
			],
			belongsTo: [
				'Drivers'
			],
			belongsToMany: [
				'Routes'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'collections__date',
			'collections__routes',
			'collections__driver_id'
		],

	},

	entity: {
		methods: {

			getPickups: function() {
				const Pickups = this.getAssociatedRepository('Pickups');
				return Pickups.getBy((entity) => {
					return entity.pickups__collection_id === this.collections__id;
				});
			},

			getDriver: async function() {
				const Drivers = this.getAssociatedRepository('Drivers');
				let entity = Drivers.getById(this.collections__driver_id);
				if (!entity) {
					entity = await Drivers.getSingleEntityFromServer(this.collections__driver_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default Collections;
