/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import CollectionsRoutesGridColumns from './Columns/CollectionsRoutesGridColumns.js';

export default function CollectionsRoutesGrid(props) {
	return <Grid
				reference="CollectionsRoutesGrid"
				model="CollectionsRoutes"
				usePermissions={true}
				columnsConfig={CollectionsRoutesGridColumns}

				{...props}
			/>;
}