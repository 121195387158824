import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Customers = {
	
	name: 'Customers',
	
	model: {

		idProperty: 'customers__id',
		displayProperty: 'customers__title',
		sortProperty: 'customers__title',
		
		sorters: null,

		validator: yup.object({
			customers__title: yup.string().required(),
			customers__contact: yup.string().nullable(),
			customers__routes: yup.string().nullable(),
			customers__price_code: yup.string().nullable(),
			customers__tank_capacity: yup.number().required(),
			customers__is_billable: yup.boolean().nullable(),
			customers__is_active: yup.boolean().nullable(),
			customers__pick_up_instructions: yup.string().nullable(),
			customers__comments: yup.string().nullable(),
			customers__street1: yup.string().max(45).nullable(),
			customers__street2: yup.string().max(45).nullable(),
			customers__city_id: yup.number().integer().nullable(),
			customers__state_id: yup.string().max(20).nullable(),
			customers__zip: yup.string().max(45).nullable(),
			customers__phone: yup.string().max(45).nullable(),
			customers__fax: yup.string().max(45).nullable()
		}),
		
		properties: [
			{ name: 'customers__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'customers__title', mapping: 'title', title: 'Title', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Specs', },
			{ name: 'customers__contact', mapping: 'contact', title: 'Contact', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Specs', },
			{ name: 'customers__routes', mapping: 'routes', title: 'Routes', filterType: {"type":"RoutesTag"}, viewerType: {"type":"RoutesTag"}, editorType: {"type":"RoutesTagEditor","loadAfterRender":!1}, fieldGroup: '1. Specs', },
			{ name: 'customers__price_code', mapping: 'price_code', title: 'Price Code', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Specs', },
			{ name: 'customers__tank_capacity', mapping: 'tank_capacity', title: 'Tank Capacity', type: 'float', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '1. Specs', },
			{ name: 'customers__is_billable', mapping: 'is_billable', title: 'Billable?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Specs', defaultValue: 1, },
			{ name: 'customers__is_active', mapping: 'is_active', title: 'Active?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Specs', defaultValue: 1, },
			{ name: 'customers__is_stale_stats', mapping: 'is_stale_stats', title: 'Stats Stale?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Specs', },
			{ name: 'customers__avg_daily_gallons', mapping: 'avg_daily_gallons', title: 'Avg Daily Gallons', type: 'float', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '4. Calc', },
			{ name: 'customers__estimated_occupancy', mapping: 'estimated_occupancy', title: 'Estimated Occupancy', type: 'float', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '4. Calc', },
			{ name: 'customers__estimated_occupancy_percent', mapping: 'estimated_occupancy_percent', title: 'Current % Full', type: 'percent', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '4. Calc', },
			{ name: 'customers__pick_up_instructions', mapping: 'pick_up_instructions', title: 'Pick Up Instructions', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '2. Comments', },
			{ name: 'customers__comments', mapping: 'comments', title: 'Comments', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '2. Comments', },
			{ name: 'customers__street1', mapping: 'street1', title: 'Street1', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Address', },
			{ name: 'customers__street2', mapping: 'street2', title: 'Street2', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Address', },
			{ name: 'customers__city_id', mapping: 'city_id', title: 'City', type: 'int', isFk: true, fkIdField: 'cities__id', fkDisplayField: 'cities__name', filterType: {"type":"CitiesCombo","loadAfterRender":!1}, editorType: {"type":"CitiesComboEditor","loadAfterRender":!1}, fieldGroup: '3. Address', },
			{ name: 'customers__state_id', mapping: 'state_id', title: 'State', isFk: true, fkIdField: 'states__id', fkDisplayField: 'states__name', isFilteringDisabled: true, editorType: {"type":"StatesCombo","loadAfterRender":!1}, fieldGroup: '3. Address', },
			{ name: 'customers__zip', mapping: 'zip', title: 'Zip', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Address', },
			{ name: 'customers__phone', mapping: 'phone', title: 'Phone', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Address', },
			{ name: 'customers__fax', mapping: 'fax', title: 'Fax', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Address', },
			{ name: 'states__id', mapping: 'state.id', title: 'Id', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'states__name', mapping: 'state.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'cities__id', mapping: 'city.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'cities__name', mapping: 'city.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'Pickups',
				'CustomersRoutes'
			],
			belongsTo: [
				'States',
				'Cities'
			],
			belongsToMany: [
				'Routes'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'customers__routes',
			// 'customers__tank_capacity',
			// 'customers__is_billable',
			// 'customers__is_active',
			// 'customers__is_stale_stats',
			// 'customers__avg_daily_gallons',
			// 'customers__estimated_occupancy',
			// 'customers__estimated_occupancy_percent',
			// 'customers__city_id'
		],

	},

	entity: {
		methods: {

			getState: async function() {
				const States = this.getAssociatedRepository('States');
				let entity = States.getById(this.customers__state_id);
				if (!entity) {
					entity = await States.getSingleEntityFromServer(this.customers__state_id);
				}
				return entity;
			},

			getPickups: function() {
				const Pickups = this.getAssociatedRepository('Pickups');
				return Pickups.getBy((entity) => {
					return entity.pickups__customer_id === this.customers__id;
				});
			},

			getCity: async function() {
				const Cities = this.getAssociatedRepository('Cities');
				let entity = Cities.getById(this.customers__city_id);
				if (!entity) {
					entity = await Cities.getSingleEntityFromServer(this.customers__city_id);
				}
				return entity;
			},

			getCustomersRoutes: function() {
				const CustomersRoutes = this.getAssociatedRepository('CustomersRoutes');
				return CustomersRoutes.getBy((entity) => {
					return entity.customers_routes__customer_id === this.customers__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default Customers;
