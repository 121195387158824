import * as yup from 'yup'; // https://github.com/jquense/yup#string

const CollectionsRoutes = {
	
	name: 'CollectionsRoutes',
	
	model: {

		idProperty: 'collections_routes__id',
		displayProperty: 'collections_routes__id',
		sortProperty: 'collections_routes__id',
		
		sorters: null,

		validator: yup.object({
			collections_routes__collection_id: yup.number().integer().required(),
			collections_routes__route_id: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'collections_routes__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'collections_routes__collection_id', mapping: 'collection_id', title: 'Collection', type: 'int', isFk: true, fkIdField: 'collections__id', fkDisplayField: 'collections__date', filterType: {"type":"CollectionsCombo","loadAfterRender":!1}, editorType: {"type":"CollectionsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'collections_routes__route_id', mapping: 'route_id', title: 'Route', type: 'int', isFk: true, fkIdField: 'routes__id', fkDisplayField: 'routes__name', filterType: {"type":"RoutesCombo","loadAfterRender":!1}, editorType: {"type":"RoutesComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'collections__id', mapping: 'collection.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'collections__date', mapping: 'collection.date', title: 'Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'collections__routes', mapping: 'collection.routes', title: 'Routes', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'collections__driver_id', mapping: 'collection.driver_id', title: 'Driver', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'routes__id', mapping: 'route.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'routes__name', mapping: 'route.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Collections',
				'Routes'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'collections_routes__collection_id',
			// 'collections_routes__route_id'
		],

	},

	entity: {
		methods: {

			getCollection: async function() {
				const Collections = this.getAssociatedRepository('Collections');
				let entity = Collections.getById(this.collections_routes__collection_id);
				if (!entity) {
					entity = await Collections.getSingleEntityFromServer(this.collections_routes__collection_id);
				}
				return entity;
			},

			getRoute: async function() {
				const Routes = this.getAssociatedRepository('Routes');
				let entity = Routes.getById(this.collections_routes__route_id);
				if (!entity) {
					entity = await Routes.getSingleEntityFromServer(this.collections_routes__route_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false
	},

};

export default CollectionsRoutes;
