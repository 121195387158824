/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import CollectionsGridColumns from '../../Grid/Columns/CollectionsGridColumns.js';

export default function CollectionsGridPanel(props) {
	return <GridPanel
				reference="CollectionsGridPanel"
				model="Collections"
				usePermissions={true}
				columnsConfig={CollectionsGridColumns}

				{...props}
			/>;
}