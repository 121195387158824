export default [
	    {
	        "id": "collections_routes__collection_id",
	        "header": "Collection",
	        "fieldName": "collections__date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "collections_routes__collection_id"
	    },
	    {
	        "id": "collections_routes__route_id",
	        "header": "Route",
	        "fieldName": "routes__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "collections_routes__route_id"
	    }
	];