import Report from '@onehat/ui/src/Components/Report/Report.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import Globals from '../../AppGlobals.js';

function ReportComponent(props) {
	const {
			self,
		} = props;
	return <Report
				title="Billing Report"
				// description="Lists all customers in system"
				reportId={5}
				disablePdf={true}
				_form={{
					items: [
						{
							"type": "Column",
							"flex": 1,
							"defaults": {},
							"items": [
								{
									name: 'startDate',
									type: 'Date',
									label: 'Start Date',
									allowBlank: true,
									tooltip: 'Earliest date to display',
								},
								{
									name: 'endDate',
									type: 'Date',
									label: 'End Date',
									allowBlank: true,
									tooltip: 'Latest date to display',
								},
							]
						},
						{
							"type": "Column",
							"flex": 1,
							"defaults": {},
							"items": [
								{
									name: 'customer_id',
									type: 'CustomersCombo',
									label: 'Customer',
									allowBlank: true,
									tooltip: 'Which customer to display results for',
									baseParams: {
										'conditions[customers__is_billable]': true,
									},
								},
							]
						},
					],
					startingValues: {
						startDate: Globals.dates.oneMonthAgo,
						endDate: Globals.dates.today,
					},
				}}
				parent={self}
			/>;
}



function withAdditionalProps(WrappedComponent) {
	return (props) => {
		return <WrappedComponent
					reference="report2"
					{...props}
				/>;
	};
}
export default withAdditionalProps(withComponent(ReportComponent));
