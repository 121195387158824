import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import RoutesFilteredGridEditor from '../components/Grid/RoutesFilteredGridEditor.js';
import RoutesFilteredSideGridEditor from '../components/Grid/RoutesFilteredSideGridEditor.js';

export default function RoutesManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'RoutesManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Routes"
				reference="RoutesManager"
				fullModeComponent={<RoutesFilteredGridEditor
										reference="RoutesGridEditor"
									/>}
				sideModeComponent={<RoutesFilteredSideGridEditor
										reference="RoutesSideGridEditor"
									/>}
			/>;
}
