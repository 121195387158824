/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function PickupsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    // {
		                    //     "name": "pickups__collection_id"
		                    // },
		                    // {
		                    //     "name": "pickups__sort_order"
		                    // },
		                    {
		                        "name": "pickups__customer_id",
								baseParams: {
									'conditions[customers__is_active]': true,
								},
		                    },
		                    {
		                        "name": "pickups__gallons_oil"
		                    },
		                    {
		                        "name": "pickups__gallons_sludge"
		                    },
		                    {
		                        "name": "pickups__gallons_left"
		                    },
		                    {
		                        "name": "pickups__comments"
		                    },
		                    {
		                        "name": "pickups__is_grease_trap_only"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="PickupsEditor"
				title="Pickups"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

