/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import CollectionsRoutesGridEditorSideA from './CollectionsRoutesGridEditorSideA.js';

export default function CollectionsRoutesFilteredGridEditorSideA(props) {
	return <CollectionsRoutesGridEditorSideA
				reference="CollectionsRoutesFilteredGridEditorSideA"
				useFilters={true}

				{...props}
			/>;
}