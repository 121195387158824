/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PickupsEditorWindow from '../Window/PickupsEditorWindow.js';
import PickupsGridColumns from './Columns/PickupsGridColumns.js';

export default function PickupsGridEditor(props) {
	return <WindowedGridEditor
				reference="PickupsGridEditor"
				model="Pickups"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={PickupsEditorWindow}
				columnsConfig={PickupsGridColumns}
				canRowsReorder={true}
				
				{...props}
			/>;
}