/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import CustomersRoutesGridEditorSideA from './CustomersRoutesGridEditorSideA.js';

export default function CustomersRoutesFilteredGridEditorSideA(props) {
	return <CustomersRoutesGridEditorSideA
				reference="CustomersRoutesFilteredGridEditorSideA"
				useFilters={true}

				{...props}
			/>;
}