export default [
	    // {
	    //     "id": "permissions__user_id",
	    //     "header": "User",
	    //     "fieldName": "users__full_name",
	    //     "isSortable": false,
	    //     "isEditable": false,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 150 // MOD,
	    //     "editField": "permissions__user_id"
	    // },
	    // {
	    //     "id": "permissions__group_id",
	    //     "header": "Group",
	    //     "fieldName": "groups__name",
	    //     "isSortable": true,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 150 // MOD,
	    //     "editField": "permissions__group_id"
	    // },
	    {
	        "id": "permissions__view_reports",
	        "header": "View Reports",
	        "fieldName": "permissions__view_reports",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_dashboard",
	        "header": "View Dashboard",
	        "fieldName": "permissions__view_dashboard",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_collections",
	        "header": "View Collections",
	        "fieldName": "permissions__view_collections",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_cities",
	        "header": "View Cities",
	        "fieldName": "permissions__view_cities",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_cities",
	        "header": "Add Cities",
	        "fieldName": "permissions__add_cities",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_cities",
	        "header": "Edit Cities",
	        "fieldName": "permissions__edit_cities",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_cities",
	        "header": "Delete Cities",
	        "fieldName": "permissions__delete_cities",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_collections",
	        "header": "Add Collections",
	        "fieldName": "permissions__add_collections",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_collections",
	        "header": "Edit Collections",
	        "fieldName": "permissions__edit_collections",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_collections",
	        "header": "Delete Collections",
	        "fieldName": "permissions__delete_collections",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_customers",
	        "header": "View Customers",
	        "fieldName": "permissions__view_customers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_collections_routes",
	        "header": "View Collections Routes",
	        "fieldName": "permissions__view_collections_routes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_collections_routes",
	        "header": "Add Collections Routes",
	        "fieldName": "permissions__add_collections_routes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_collections_routes",
	        "header": "Edit Collections Routes",
	        "fieldName": "permissions__edit_collections_routes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_collections_routes",
	        "header": "Delete Collections Routes",
	        "fieldName": "permissions__delete_collections_routes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_customers",
	        "header": "Add Customers",
	        "fieldName": "permissions__add_customers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_customers",
	        "header": "Edit Customers",
	        "fieldName": "permissions__edit_customers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_customers",
	        "header": "Delete Customers",
	        "fieldName": "permissions__delete_customers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_customers_routes",
	        "header": "View Customers Routes",
	        "fieldName": "permissions__view_customers_routes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_customers_routes",
	        "header": "Add Customers Routes",
	        "fieldName": "permissions__add_customers_routes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_customers_routes",
	        "header": "Edit Customers Routes",
	        "fieldName": "permissions__edit_customers_routes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_customers_routes",
	        "header": "Delete Customers Routes",
	        "fieldName": "permissions__delete_customers_routes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_drivers",
	        "header": "View Drivers",
	        "fieldName": "permissions__view_drivers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_drivers",
	        "header": "Add Drivers",
	        "fieldName": "permissions__add_drivers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_drivers",
	        "header": "Edit Drivers",
	        "fieldName": "permissions__edit_drivers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_drivers",
	        "header": "Delete Drivers",
	        "fieldName": "permissions__delete_drivers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_groups",
	        "header": "View Groups",
	        "fieldName": "permissions__view_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_groups",
	        "header": "Add Groups",
	        "fieldName": "permissions__add_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_groups",
	        "header": "Edit Groups",
	        "fieldName": "permissions__edit_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_groups",
	        "header": "Delete Groups",
	        "fieldName": "permissions__delete_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_groups_users",
	        "header": "View Groups Users",
	        "fieldName": "permissions__view_groups_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_groups_users",
	        "header": "Add Groups Users",
	        "fieldName": "permissions__add_groups_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_groups_users",
	        "header": "Edit Groups Users",
	        "fieldName": "permissions__edit_groups_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_groups_users",
	        "header": "Delete Groups Users",
	        "fieldName": "permissions__delete_groups_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_permissions",
	        "header": "View Permissions",
	        "fieldName": "permissions__view_permissions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_permissions",
	        "header": "Add Permissions",
	        "fieldName": "permissions__add_permissions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_permissions",
	        "header": "Edit Permissions",
	        "fieldName": "permissions__edit_permissions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_permissions",
	        "header": "Delete Permissions",
	        "fieldName": "permissions__delete_permissions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_pickups",
	        "header": "View Pickups",
	        "fieldName": "permissions__view_pickups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_pickups",
	        "header": "Add Pickups",
	        "fieldName": "permissions__add_pickups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_pickups",
	        "header": "Edit Pickups",
	        "fieldName": "permissions__edit_pickups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_pickups",
	        "header": "Delete Pickups",
	        "fieldName": "permissions__delete_pickups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_routes",
	        "header": "View Routes",
	        "fieldName": "permissions__view_routes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_routes",
	        "header": "Add Routes",
	        "fieldName": "permissions__add_routes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_routes",
	        "header": "Edit Routes",
	        "fieldName": "permissions__edit_routes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_routes",
	        "header": "Delete Routes",
	        "fieldName": "permissions__delete_routes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_states",
	        "header": "View States",
	        "fieldName": "permissions__view_states",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_states",
	        "header": "Add States",
	        "fieldName": "permissions__add_states",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_states",
	        "header": "Edit States",
	        "fieldName": "permissions__edit_states",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_states",
	        "header": "Delete States",
	        "fieldName": "permissions__delete_states",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__view_users",
	        "header": "View Users",
	        "fieldName": "permissions__view_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__add_users",
	        "header": "Add Users",
	        "fieldName": "permissions__add_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__edit_users",
	        "header": "Edit Users",
	        "fieldName": "permissions__edit_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "permissions__delete_users",
	        "header": "Delete Users",
	        "fieldName": "permissions__delete_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    }
	];