import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Permissions = {
	
	name: 'Permissions',
	
	model: {

		idProperty: 'permissions__id',
		displayProperty: 'permissions__id',
		sortProperty: 'permissions__id',
		
		sorters: null,

		validator: yup.object({
			permissions__user_id: yup.number().integer().nullable(),
			permissions__group_id: yup.number().integer().nullable(),
			permissions__view_reports: yup.boolean().nullable(),
			permissions__view_dashboard: yup.boolean().nullable(),
			permissions__view_collections: yup.boolean().nullable(),
			permissions__view_cities: yup.boolean().nullable(),
			permissions__add_cities: yup.boolean().nullable(),
			permissions__edit_cities: yup.boolean().nullable(),
			permissions__delete_cities: yup.boolean().nullable(),
			permissions__add_collections: yup.boolean().nullable(),
			permissions__edit_collections: yup.boolean().nullable(),
			permissions__delete_collections: yup.boolean().nullable(),
			permissions__view_customers: yup.boolean().nullable(),
			permissions__view_collections_routes: yup.boolean().nullable(),
			permissions__add_collections_routes: yup.boolean().nullable(),
			permissions__edit_collections_routes: yup.boolean().nullable(),
			permissions__delete_collections_routes: yup.boolean().nullable(),
			permissions__add_customers: yup.boolean().nullable(),
			permissions__edit_customers: yup.boolean().nullable(),
			permissions__delete_customers: yup.boolean().nullable(),
			permissions__view_customers_routes: yup.boolean().nullable(),
			permissions__add_customers_routes: yup.boolean().nullable(),
			permissions__edit_customers_routes: yup.boolean().nullable(),
			permissions__delete_customers_routes: yup.boolean().nullable(),
			permissions__view_drivers: yup.boolean().nullable(),
			permissions__add_drivers: yup.boolean().nullable(),
			permissions__edit_drivers: yup.boolean().nullable(),
			permissions__delete_drivers: yup.boolean().nullable(),
			permissions__view_groups: yup.boolean().nullable(),
			permissions__add_groups: yup.boolean().nullable(),
			permissions__edit_groups: yup.boolean().nullable(),
			permissions__delete_groups: yup.boolean().nullable(),
			permissions__view_groups_users: yup.boolean().nullable(),
			permissions__add_groups_users: yup.boolean().nullable(),
			permissions__edit_groups_users: yup.boolean().nullable(),
			permissions__delete_groups_users: yup.boolean().nullable(),
			permissions__view_permissions: yup.boolean().nullable(),
			permissions__add_permissions: yup.boolean().nullable(),
			permissions__edit_permissions: yup.boolean().nullable(),
			permissions__delete_permissions: yup.boolean().nullable(),
			permissions__view_pickups: yup.boolean().nullable(),
			permissions__add_pickups: yup.boolean().nullable(),
			permissions__edit_pickups: yup.boolean().nullable(),
			permissions__delete_pickups: yup.boolean().nullable(),
			permissions__view_routes: yup.boolean().nullable(),
			permissions__add_routes: yup.boolean().nullable(),
			permissions__edit_routes: yup.boolean().nullable(),
			permissions__delete_routes: yup.boolean().nullable(),
			permissions__view_states: yup.boolean().nullable(),
			permissions__add_states: yup.boolean().nullable(),
			permissions__edit_states: yup.boolean().nullable(),
			permissions__delete_states: yup.boolean().nullable(),
			permissions__view_users: yup.boolean().nullable(),
			permissions__add_users: yup.boolean().nullable(),
			permissions__edit_users: yup.boolean().nullable(),
			permissions__delete_users: yup.boolean().nullable()
		}),
		
		properties: [
			{ name: 'permissions__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'permissions__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__full_name', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersComboEditor","loadAfterRender":!1}, fieldGroup: '1. General', },
			{ name: 'permissions__group_id', mapping: 'group_id', title: 'Group', type: 'int', isFk: true, fkIdField: 'groups__id', fkDisplayField: 'groups__name', filterType: {"type":"GroupsCombo","loadAfterRender":!1}, editorType: {"type":"GroupsComboEditor","loadAfterRender":!1}, fieldGroup: '1. General', },
			{ name: 'permissions__view_reports', mapping: 'view_reports', title: 'View Reports', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. General', },
			{ name: 'permissions__view_dashboard', mapping: 'view_dashboard', title: 'View Dashboard', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. General', },
			{ name: 'permissions__view_collections', mapping: 'view_collections', title: 'View Collections', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Collections', },
			{ name: 'permissions__view_cities', mapping: 'view_cities', title: 'View Cities', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Cities', },
			{ name: 'permissions__add_cities', mapping: 'add_cities', title: 'Add Cities', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Cities', },
			{ name: 'permissions__edit_cities', mapping: 'edit_cities', title: 'Edit Cities', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Cities', },
			{ name: 'permissions__delete_cities', mapping: 'delete_cities', title: 'Delete Cities', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Cities', },
			{ name: 'permissions__add_collections', mapping: 'add_collections', title: 'Add Collections', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Collections', },
			{ name: 'permissions__edit_collections', mapping: 'edit_collections', title: 'Edit Collections', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Collections', },
			{ name: 'permissions__delete_collections', mapping: 'delete_collections', title: 'Delete Collections', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Collections', },
			{ name: 'permissions__view_customers', mapping: 'view_customers', title: 'View Customers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Customers', },
			{ name: 'permissions__view_collections_routes', mapping: 'view_collections_routes', title: 'View Collections Routes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Collections Routes', },
			{ name: 'permissions__add_collections_routes', mapping: 'add_collections_routes', title: 'Add Collections Routes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Collections Routes', },
			{ name: 'permissions__edit_collections_routes', mapping: 'edit_collections_routes', title: 'Edit Collections Routes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Collections Routes', },
			{ name: 'permissions__delete_collections_routes', mapping: 'delete_collections_routes', title: 'Delete Collections Routes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Collections Routes', },
			{ name: 'permissions__add_customers', mapping: 'add_customers', title: 'Add Customers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Customers', },
			{ name: 'permissions__edit_customers', mapping: 'edit_customers', title: 'Edit Customers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Customers', },
			{ name: 'permissions__delete_customers', mapping: 'delete_customers', title: 'Delete Customers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Customers', },
			{ name: 'permissions__view_customers_routes', mapping: 'view_customers_routes', title: 'View Customers Routes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Customers Routes', },
			{ name: 'permissions__add_customers_routes', mapping: 'add_customers_routes', title: 'Add Customers Routes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Customers Routes', },
			{ name: 'permissions__edit_customers_routes', mapping: 'edit_customers_routes', title: 'Edit Customers Routes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Customers Routes', },
			{ name: 'permissions__delete_customers_routes', mapping: 'delete_customers_routes', title: 'Delete Customers Routes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Customers Routes', },
			{ name: 'permissions__view_drivers', mapping: 'view_drivers', title: 'View Drivers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. Drivers', },
			{ name: 'permissions__add_drivers', mapping: 'add_drivers', title: 'Add Drivers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. Drivers', },
			{ name: 'permissions__edit_drivers', mapping: 'edit_drivers', title: 'Edit Drivers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. Drivers', },
			{ name: 'permissions__delete_drivers', mapping: 'delete_drivers', title: 'Delete Drivers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. Drivers', },
			{ name: 'permissions__view_groups', mapping: 'view_groups', title: 'View Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Groups', },
			{ name: 'permissions__add_groups', mapping: 'add_groups', title: 'Add Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Groups', },
			{ name: 'permissions__edit_groups', mapping: 'edit_groups', title: 'Edit Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Groups', },
			{ name: 'permissions__delete_groups', mapping: 'delete_groups', title: 'Delete Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Groups', },
			{ name: 'permissions__view_groups_users', mapping: 'view_groups_users', title: 'View Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Groups Users', },
			{ name: 'permissions__add_groups_users', mapping: 'add_groups_users', title: 'Add Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Groups Users', },
			{ name: 'permissions__edit_groups_users', mapping: 'edit_groups_users', title: 'Edit Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Groups Users', },
			{ name: 'permissions__delete_groups_users', mapping: 'delete_groups_users', title: 'Delete Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Groups Users', },
			{ name: 'permissions__view_permissions', mapping: 'view_permissions', title: 'View Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Permissions', },
			{ name: 'permissions__add_permissions', mapping: 'add_permissions', title: 'Add Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Permissions', },
			{ name: 'permissions__edit_permissions', mapping: 'edit_permissions', title: 'Edit Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Permissions', },
			{ name: 'permissions__delete_permissions', mapping: 'delete_permissions', title: 'Delete Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Permissions', },
			{ name: 'permissions__view_pickups', mapping: 'view_pickups', title: 'View Pickups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Pickups', },
			{ name: 'permissions__add_pickups', mapping: 'add_pickups', title: 'Add Pickups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Pickups', },
			{ name: 'permissions__edit_pickups', mapping: 'edit_pickups', title: 'Edit Pickups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Pickups', },
			{ name: 'permissions__delete_pickups', mapping: 'delete_pickups', title: 'Delete Pickups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Pickups', },
			{ name: 'permissions__view_routes', mapping: 'view_routes', title: 'View Routes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. Routes', },
			{ name: 'permissions__add_routes', mapping: 'add_routes', title: 'Add Routes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. Routes', },
			{ name: 'permissions__edit_routes', mapping: 'edit_routes', title: 'Edit Routes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. Routes', },
			{ name: 'permissions__delete_routes', mapping: 'delete_routes', title: 'Delete Routes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. Routes', },
			{ name: 'permissions__view_states', mapping: 'view_states', title: 'View States', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. States', },
			{ name: 'permissions__add_states', mapping: 'add_states', title: 'Add States', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. States', },
			{ name: 'permissions__edit_states', mapping: 'edit_states', title: 'Edit States', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. States', },
			{ name: 'permissions__delete_states', mapping: 'delete_states', title: 'Delete States', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. States', },
			{ name: 'permissions__view_users', mapping: 'view_users', title: 'View Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '16. users', },
			{ name: 'permissions__add_users', mapping: 'add_users', title: 'Add Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '16. users', },
			{ name: 'permissions__edit_users', mapping: 'edit_users', title: 'Edit Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '16. users', },
			{ name: 'permissions__delete_users', mapping: 'delete_users', title: 'Delete Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '16. users', },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__job_title', mapping: 'user.job_title', title: 'Job Title', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__id', mapping: 'group.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__name', mapping: 'group.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__description', mapping: 'group.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Users',
				'Groups'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'permissions__user_id',
			// 'permissions__group_id',
			// 'permissions__view_reports',
			// 'permissions__view_dashboard',
			// 'permissions__view_collections',
			// 'permissions__view_cities',
			// 'permissions__add_cities',
			// 'permissions__edit_cities',
			// 'permissions__delete_cities',
			// 'permissions__add_collections',
			// 'permissions__edit_collections',
			// 'permissions__delete_collections',
			// 'permissions__view_customers',
			// 'permissions__view_collections_routes',
			// 'permissions__add_collections_routes',
			// 'permissions__edit_collections_routes',
			// 'permissions__delete_collections_routes',
			// 'permissions__add_customers',
			// 'permissions__edit_customers',
			// 'permissions__delete_customers',
			// 'permissions__view_customers_routes',
			// 'permissions__add_customers_routes',
			// 'permissions__edit_customers_routes',
			// 'permissions__delete_customers_routes',
			// 'permissions__view_drivers',
			// 'permissions__add_drivers',
			// 'permissions__edit_drivers',
			// 'permissions__delete_drivers',
			// 'permissions__view_groups',
			// 'permissions__add_groups',
			// 'permissions__edit_groups',
			// 'permissions__delete_groups',
			// 'permissions__view_groups_users',
			// 'permissions__add_groups_users',
			// 'permissions__edit_groups_users',
			// 'permissions__delete_groups_users',
			// 'permissions__view_permissions',
			// 'permissions__add_permissions',
			// 'permissions__edit_permissions',
			// 'permissions__delete_permissions',
			// 'permissions__view_pickups',
			// 'permissions__add_pickups',
			// 'permissions__edit_pickups',
			// 'permissions__delete_pickups',
			// 'permissions__view_routes',
			// 'permissions__add_routes',
			// 'permissions__edit_routes',
			// 'permissions__delete_routes',
			// 'permissions__view_states',
			// 'permissions__add_states',
			// 'permissions__edit_states',
			// 'permissions__delete_states',
			// 'permissions__view_users',
			// 'permissions__add_users',
			// 'permissions__edit_users',
			// 'permissions__delete_users'
		],

	},

	entity: {
		methods: {

			getUser: async function() {
				const Users = this.getAssociatedRepository('Users');
				let entity = Users.getById(this.permissions__user_id);
				if (!entity) {
					entity = await Users.getSingleEntityFromServer(this.permissions__user_id);
				}
				return entity;
			},

			getGroup: async function() {
				const Groups = this.getAssociatedRepository('Groups');
				let entity = Groups.getById(this.permissions__group_id);
				if (!entity) {
					entity = await Groups.getSingleEntityFromServer(this.permissions__group_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Permissions;
