export default [
	    {
	        "id": "collections_routes__collection_id",
	        "header": "Collection",
	        "fieldName": "collections__date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "editField": "collections_routes__collection_id",
	        "flex": 1,
	        "minWidth": 150
	    }
	];