/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import CustomersRoutesInlineGridEditor from './CustomersRoutesInlineGridEditor.js';

export default function CustomersRoutesFilteredInlineGridEditor(props) {
	return <CustomersRoutesInlineGridEditor
				reference="CustomersRoutesFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}