/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import CollectionsEditorWindow from '../Window/CollectionsEditorWindow.js';
import CollectionsGridColumns from './Columns/CollectionsGridColumns.js';

export default function CollectionsGridEditor(props) {
	return <WindowedGridEditor
				reference="CollectionsGridEditor"
				model="Collections"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={CollectionsEditorWindow}
				columnsConfig={CollectionsGridColumns}
				
				
				{...props}
			/>;
}