export default [
	    {
	        "id": "customers_routes__customer_id",
	        "header": "Customer",
	        "fieldName": "customers__title",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "customers_routes__customer_id"
	    },
	    {
	        "id": "customers_routes__route_id",
	        "header": "Route",
	        "fieldName": "routes__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "customers_routes__route_id"
	    }
	];