/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import CollectionsRoutesSideGridEditorPanel from './CollectionsRoutesSideGridEditor.js';


export default function CollectionsRoutesFilteredSideGridEditorPanel(props) {
	return <CollectionsRoutesSideGridEditorPanel
				reference="CollectionsRoutesFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}